import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import classNames from 'classnames'

import { ReactComponent as DashIcon } from '../../assets/dashIcon.svg'
import { ReactComponent as ArrowUp } from '../../assets/chartArrowUp.svg'
import { ReactComponent as ArrowDown } from '../../assets/chartArrowDown.svg'

import Table from '../../../../../../components/atoms/table'

import { transition } from '../../../../../../styles'

type Commodity = any

const mappedCommodityString = {
  POWER: {
    suffix: ' £',
    prefix: '',
  },
  GAS: {
    suffix: ' p/th',
    prefix: '',
  },
  COAL: {
    suffix: '',
    prefix: '',
  },
}

export const monthPeriods = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
] as const

type PeriodSeason = 'Summer' | 'Winter'
type PeriodQuarter = '1Q' | '2Q' | '3Q' | '4Q'
type PeriodMonth = typeof monthPeriods[number]
type PeriodWithYear = `${PeriodSeason | PeriodQuarter | PeriodMonth}-${number}`
type PeriodDayAhead = 'Day'

export type Period = PeriodWithYear | PeriodDayAhead

export const toShortTime = (date: string, time: string) => {
  if (!date || !time) return '—'

  const dateTimeString = `${date} ${time}`
  return dayjs(dateTimeString, 'YYYY-MM-DD HH:mm:ss').format('DD/MM HH:mm')
}

const commodityChangeStyle = (commodityChange?: number) => {
  if (!commodityChange)
    return (
      'text-ppa/yellowPriceChange' && <DashIcon className="ml-2 w-2.5 h-2.5" />
    )
  if (commodityChange > 0)
    return 'text-ppa/greenPriceChange' && <ArrowUp className="ml-2 w-5 h-5" />
  if (commodityChange < 0)
    return 'text-ppa/redError' && <ArrowDown className="ml-2 w-5 h-5" />
  return commodityChange
}

export interface ForecastData {
  id: string
  name: string
  period: Period
  year: string
  openPrice?: number
  historicalPrice?: number
  tradePrice?: number
  highPrice?: number
  lowPrice?: number
  tradeDate: string
  tradeTime: string
  bid?: number
  ask?: number
}

export interface PeriodData {
  latest: ForecastData
  previous: ForecastData
}

export type PowerListData = Record<Period, PeriodData>[]

interface ForecastProps {
  commodity: Commodity
  data: PowerListData
  onClickRow: (row: ForecastData) => void
}

const periodOrder = {
  Day: 0,
  JAN: 1,
  FEB: 2,
  MAR: 3,
  APR: 4,
  MAY: 5,
  JUN: 6,
  JUL: 7,
  AUG: 8,
  SEP: 9,
  OCT: 10,
  NOV: 11,
  DEC: 12,
  '1Q': 13,
  '2Q': 14,
  '3Q': 15,
  '4Q': 16,
  Winter: 17,
  Summer: 18,
}

export const convertPeriodToSortableValue = (period: any) => {
  const periodParts = period.split('-')
  const periodType = periodParts[0] as keyof typeof periodOrder
  const year = parseInt(periodParts[1], 10) || 0

  let order = periodOrder[periodType]

  if (
    [
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MAY',
      'JUN',
      'JUL',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC',
    ].includes(periodType)
  ) {
    return year * 100 + order
  }

  if (periodType.startsWith('Q')) {
    const quarterNumber = parseInt(periodType, 10)
    order = 1000 + quarterNumber
  } else if (periodType === 'Winter') {
    order += 110
  } else if (periodType === 'Summer') {
    order += 100
  }

  return year * 10000 + order * 100
}

const ForecastTable: React.FC<ForecastProps> = ({
  commodity,
  data,
  onClickRow,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'market.forecast',
  })

  const sortedData = Object.keys(data)
    .map((key) => ({ [key]: data[key as any] }))
    .sort((a, b) => {
      const periodA = Object.keys(a)[0]
      const periodB = Object.keys(b)[0]

      const orderA = convertPeriodToSortableValue(periodA)
      const orderB = convertPeriodToSortableValue(periodB)

      return orderA - orderB
    })

  return (
    <div
      className={classNames(transition, 'fade-in', 'flex flex-col', 'w-full')}
    >
      <Table
        headers={[
          t('table.headers.product'),
          t('table.headers.latest'),
          t('table.headers.previous'),
          t('table.headers.changes'),
        ]}
        onRowClick={(item: ForecastData) => {
          onClickRow(item)
        }}
        data={sortedData}
        rowKeys={[
          {
            keyName: 'title',
            renderCustomEl: (item: any) => {
              const [period] = Object.keys(item)

              let label: string
              if (period === 'Day') label = t('table.dayAhead')
              else if (period.includes('Summer') || period.includes('Winter')) {
                const [season, year] = period.split('-')
                label = `${t(`table.seasons.${season}`)} ${year}`
              } else if (period.includes('Q')) {
                const [quarter, year] = period.split('Q-')
                label = `${t('table.quarter')}${quarter} ${year}`
              } else {
                const [month, year] = period.split('-')
                label = `${t(`table.months.${month}`)} ${year}`
              }

              return <span>{label}</span>
            },
          },
          {
            keyName: 'latest',
            renderCustomEl: (item: any) => {
              const periods = Object.keys(item)
              if (periods.length > 0) {
                const period = periods[0]
                const latest = item[period]?.latest
                if (latest?.tradePrice) {
                  return (
                    <span className="max-w-[250px] text-right">
                      {
                        mappedCommodityString[
                          commodity as keyof typeof mappedCommodityString
                        ].prefix
                      }
                      {latest.tradePrice.toFixed(2)}
                      {
                        mappedCommodityString[
                          commodity as keyof typeof mappedCommodityString
                        ].suffix
                      }
                    </span>
                  )
                }
                if (!latest?.tradePrice && latest.historicalClose) {
                  return (
                    <span className="max-w-[250px] text-right">
                      {
                        mappedCommodityString[
                          commodity as keyof typeof mappedCommodityString
                        ].prefix
                      }
                      {latest.historicalClose.toFixed(2)}
                      {
                        mappedCommodityString[
                          commodity as keyof typeof mappedCommodityString
                        ].suffix
                      }
                    </span>
                  )
                }
              }
              return ''
            },
          },
          {
            keyName: 'previous',
            renderCustomEl: (item: any) => {
              const periods = Object.keys(item)
              if (periods.length > 0) {
                const period = periods[0]
                const previous = item[period]?.previous
                if (previous?.openPrice) {
                  return (
                    <span className="min-w-[60px] max-w-[250px] text-right">
                      {
                        mappedCommodityString[
                          commodity as keyof typeof mappedCommodityString
                        ].prefix
                      }
                      {previous.openPrice.toFixed(2)}
                      {
                        mappedCommodityString[
                          commodity as keyof typeof mappedCommodityString
                        ].suffix
                      }
                    </span>
                  )
                }
                if (!previous?.openPrice && previous?.historicalClose) {
                  return (
                    <span className="min-w-[60px] max-w-[250px] text-right">
                      {
                        mappedCommodityString[
                          commodity as keyof typeof mappedCommodityString
                        ].prefix
                      }
                      {previous.historicalClose.toFixed(2)}
                      {
                        mappedCommodityString[
                          commodity as keyof typeof mappedCommodityString
                        ].suffix
                      }
                    </span>
                  )
                }
              }
              return ''
            },
          },
          {
            keyName: 'change',
            renderCustomEl: (item: any) => {
              const periods = Object.keys(item)
              if (periods.length > 0) {
                const period = periods[0]
                const latest = item[period]?.latest
                const previous = item[period]?.previous
                if (previous?.openPrice && latest?.tradePrice) {
                  const change = latest.tradePrice - previous.openPrice
                  return (
                    <div className="flex items-center justify-end">
                      <span
                        className={classNames(commodityChangeStyle(change))}
                      >
                        {
                          mappedCommodityString[
                            commodity as keyof typeof mappedCommodityString
                          ].prefix
                        }
                        {change.toFixed(2)}
                        {
                          mappedCommodityString[
                            commodity as keyof typeof mappedCommodityString
                          ].suffix
                        }
                      </span>
                      {commodityChangeStyle(change)}
                    </div>
                  )
                }
                if (
                  previous?.openPrice &&
                  !latest?.tradePrice &&
                  latest?.historicalClose
                ) {
                  const change = latest.historicalClose - previous.openPrice
                  return (
                    <div className="flex items-center justify-end">
                      <span
                        className={classNames(commodityChangeStyle(change))}
                      >
                        {
                          mappedCommodityString[
                            commodity as keyof typeof mappedCommodityString
                          ].prefix
                        }
                        {change.toFixed(2)}
                        {
                          mappedCommodityString[
                            commodity as keyof typeof mappedCommodityString
                          ].suffix
                        }
                      </span>
                      {commodityChangeStyle(change)}
                    </div>
                  )
                }
                return (
                  <div className="flex items-center justify-end">
                    <span
                      className={classNames(
                        commodityChangeStyle(
                          latest && previous
                            ? latest.historicalClose - previous.historicalClose
                            : 0,
                        ),
                      )}
                    >
                      {latest && previous && (
                        <>
                          {
                            mappedCommodityString[
                              commodity as keyof typeof mappedCommodityString
                            ].prefix
                          }
                          {(
                            latest.historicalClose - previous.historicalClose
                          ).toFixed(2)}
                          {
                            mappedCommodityString[
                              commodity as keyof typeof mappedCommodityString
                            ].suffix
                          }
                        </>
                      )}
                    </span>
                    {commodityChangeStyle(
                      latest && previous
                        ? latest.historicalClose - previous.historicalClose
                        : 0,
                    )}
                  </div>
                )
              }
              return ''
            },
          },
        ]}
      />
    </div>
  )
}

export default ForecastTable
