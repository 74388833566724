import React, { useEffect } from 'react'

import usePPAGetList from '../../../../../hooks/use-ppa/use-ppa-get-list'

import { SupplierTenderBid } from '../../../../../types'

import SupplierTableBids from '../../../../../components/organisms/supplier-bids-table'

import TenderDetails from '../components/details'

import { SleevedTenderDetailsProps } from '../details'

const DraftSleevedTenderDetails: React.FC<SleevedTenderDetailsProps> = ({
  tender,
}) => {
  return (
    <section className="flex flex-col gap-y-8">
      <TenderDetails tender={tender} />
    </section>
  )
}

export default DraftSleevedTenderDetails
