/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { ChevronLeftIcon, DownloadIcon } from '@heroicons/react/outline'
import classNames from 'classnames'

import usePPA from '../../../../../../hooks/use-ppa'
import { parseQuery } from '../../../../../../hooks/use-ppa/use-ppa-get-list'

import {
  dateFormats,
  mappedMatchPercentage,
} from '../../../../../../utils/data'

import {
  parseSnippetAddress,
  parseContractDuration,
} from '../../../../../../utils'

import { container, breakPointsContainer } from '../../../../../../styles'

import Button, {
  Props as ButtonProps,
} from '../../../../../../components/atoms/button'
import DefList from '../../../../../../components/atoms/def-list'
import TenderPill from '../../../../components/tender-pill'

import CollapseList from '../../../../../../components/molecules/collapse-list'
import DownloadHhd from '../../../../../../components/organisms/latest-hhd-download'

import CompanyDefListModal from '../../../../../../components/organisms/company-details-modal'
import SupplierDefListModal from '../../../../../../components/organisms/supplier-details-modal'

import {
  ConsumerTenderWithInvitation,
  Meter,
  Supplier,
  SupplierProfile,
} from '../../../../../../types'

import { mappedTenderStatus } from '../../../types'

dayjs.extend(utc)
dayjs.extend(timezone)

type ParticipatingSupplier = Supplier & {
  profile: SupplierProfile
}

export interface ButtonList {
  props: ButtonProps
  text: string
  onClick: () => void
}
interface TenderDetailsProps {
  tender: ConsumerTenderWithInvitation
  buttons?: ButtonList[]
}

const TenderDetails: React.FC<TenderDetailsProps> = ({ tender, buttons }) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.consumerTenders.details',
  })

  const navigate = useNavigate()

  const { fetchData, throwFeedbackError } = usePPA()

  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false)
  const [isSupplierModalOpen, setIsSupplierModalOpen] = useState(false)
  const [selectedSupplier, setSelectedSupplier] =
    useState<ParticipatingSupplier | null>(null)

  const [isLoadingHhdFiles, setIsLoadingHhdFiles] = useState(false)
  const [hhdFiles, setHhdFiles] = useState<File[] | undefined>()

  const rowKeysDetails = useMemo(() => {
    const details = [
      {
        keyName: 'contractDuration',
        title: t('list.titles.contractDuration'),
        renderCustomEl: (item: ConsumerTenderWithInvitation) => {
          return parseContractDuration(item.startDate, item.endDate)
        },
      },
      {
        keyName: 'deadline',
        title: t('list.titles.deadline'),
        renderCustomEl: (item: ConsumerTenderWithInvitation) => {
          if (!item?.deadline) return undefined

          return dayjs
            .utc(item.deadline)
            .tz('Europe/London')
            .format(dateFormats.userDateTime)
        },
      },
      ...(tender?.preferredContractType === 'SLEEVED'
        ? [
            {
              keyName: 'targetMatch',
              title: t('list.titles.targetMatch'),
              renderCustomEl: (item: ConsumerTenderWithInvitation) => {
                if (!item?.targetMatch) return undefined
                return mappedMatchPercentage[item.targetMatch]
              },
            },
          ]
        : []),
      {
        keyName: 'participatingSuppliers',
        title: t('list.titles.participatingSuppliers'),
        renderCustomEl: (item: ConsumerTenderWithInvitation) => {
          if (!item?.consumerTenderInvitations) return undefined
          const participatingSuppliers = item.consumerTenderInvitations.map(
            (invitation, index) => (
              <span
                key={invitation.supplier.id}
                onClick={() => {
                  setSelectedSupplier(invitation.supplier as any)
                  setIsSupplierModalOpen(true)
                }}
                className="border-b border-ppa/grayText cursor-pointer"
              >
                {invitation.supplier.name}
                {index < item.consumerTenderInvitations.length - 1 && ', '}
              </span>
            ),
          )

          return (
            <div className="cursor-pointer flex flex-row space-x-2">
              {participatingSuppliers.length ? participatingSuppliers : '-'}
            </div>
          )
        },
      },
      {
        keyName: 'brokerFee',
        title: t('list.titles.brokerFee'),
        suffix: '£/MWh',
        renderCustomEl: (item: ConsumerTenderWithInvitation) => {
          if (!item?.consumerMeter?.brokerFee) return undefined
          return item.consumerMeter.brokerFee
        },
      },
    ]

    return details
  }, [tender])

  const rowKeysSiteDetails = useMemo(() => {
    return [
      {
        keyName: 'name',
        title: t('meterDetailsList.titles.name'),
        renderCustomEl: (meter: Meter) => {
          return meter.name
        },
      },
      {
        keyName: 'mpan',
        title: t('meterDetailsList.titles.mpan'),
        renderCustomEl: (meter: Meter) => {
          if (!meter.mpan) return undefined
          return meter.mpan
        },
      },
      {
        keyName: 'hhd',
        title: t('meterDetailsList.titles.hhd'),
        renderCustomEl: (meter: Meter) => {
          if (!meter.hhdTimeFrame) return undefined
          return meter.hhdTimeFrame
        },
      },
      {
        keyName: 'annualConsumption',
        title: t('meterDetailsList.titles.annualConsumption'),
        suffix: 'MWh',
        renderCustomEl: (meter: Meter) => {
          if (!meter.annualConsumption) return undefined
          return meter.annualConsumption
        },
      },
      {
        keyName: 'gridConnectionSize',
        title: t('meterDetailsList.titles.gridConnectionSize'),
        suffix: 'kVA',
        renderCustomEl: (meter: Meter) => {
          if (!meter.gridConnectionSize) return undefined
          return meter.gridConnectionSize
        },
      },
      {
        keyName: 'company name',
        title: t('meterDetailsList.titles.company'),
        renderCustomEl: (meter: Meter) => {
          if (!meter.company) return undefined
          return (
            <div className="flex flex-col">
              <div
                onClick={() => {
                  setIsCompanyModalOpen(true)
                }}
                className="border-b border-ppa/grayText cursor-pointer w-fit"
              >
                {meter.company.name}
              </div>
              {meter.company.number && (
                <div
                  onClick={() => {
                    setIsCompanyModalOpen(true)
                  }}
                  className="border-b border-ppa/grayText cursor-pointer w-fit"
                >
                  {meter.company.number}
                </div>
              )}
              {meter.company.address && (
                <div
                  onClick={() => {
                    setIsCompanyModalOpen(true)
                  }}
                  className="border-b border-ppa/grayText cursor-pointer w-fit"
                >
                  {parseSnippetAddress(meter.company.address as any)}
                </div>
              )}
            </div>
          )
        },
      },
      {
        keyName: 'existingSupplier',
        title: t('meterDetailsList.titles.existingSupplier'),
        renderCustomEl: (meter: Meter) => {
          if (!meter.existingSupplier) return undefined
          return meter.existingSupplier
        },
      },
    ]
  }, [tender?.consumerMeter])

  const handleFetchHhdFiles = async () => {
    if (isLoadingHhdFiles) return
    setIsLoadingHhdFiles(true)

    try {
      const { response, error: fetchError } = await fetchData({
        method: 'GET',
        url: `/core/private/consumer-meter/admin/hhd/list?${parseQuery({
          meterId: tender.consumerMeter.id,
        })}`,
        responseType: 'json',
      })

      if (fetchError) throw fetchError

      const files = response?.data.hhdFiles || []

      const processedFiles = await Promise.all(
        files.map(async (fileObject: { file: string; fileName: string }) => {
          const base64Data = fileObject.file || ''
          const fileNameFromResponse =
            fileObject.fileName || 'defaultFileName.pdf'

          const binaryResponse = await fetch(
            `data:application/octet-stream;base64,${base64Data}`,
          )
          const binaryData = await binaryResponse.blob()

          const blob = new Blob([binaryData], {
            type: 'application/octet-stream',
          })

          return { blob, fileName: fileNameFromResponse }
        }),
      )

      setHhdFiles(processedFiles)
    } catch (err: any) {
      throwFeedbackError(err)
    } finally {
      setIsLoadingHhdFiles(false)
    }
  }

  const handleDownloadHHD = async (file: File) => {
    if (!tender?.consumerMeter.id) return
    try {
      const { response, error: downloadError } = await fetchData({
        method: 'GET',
        url: `/core/private/consumer-meter/admin/hhd?${parseQuery({
          meterId: tender.consumerMeter.id,
          fileName: file.name,
        })}`,
        responseType: 'json',
      })

      if (downloadError) throw downloadError

      const base64Data = response?.data.hhdFile

      const binaryResponse = await fetch(
        `data:application/octet-stream;base64,${base64Data}`,
      )
      const binaryData = await binaryResponse.blob()

      const blob = new Blob([binaryData], {
        type: 'application/octet-stream',
      })

      const url = URL.createObjectURL(blob)

      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.setAttribute('download', file.name)
      downloadLink.target = '_blank'
      downloadLink.click()

      URL.revokeObjectURL(url)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    handleFetchHhdFiles()
  }, [])

  return (
    <section
      className={classNames(
        container,
        breakPointsContainer,
        'items-center pt-12 relative gap-y-8',
      )}
    >
      <div className="absolute top-0 left-0 -mx-3 hover:brightness-50 cursor-pointer">
        <Button
          variant="text"
          icon={<ChevronLeftIcon />}
          onClick={() =>
            navigate(
              `/consumerTenders/status/${mappedTenderStatus[tender.status]}`,
            )
          }
        >
          {t('backButton')}
        </Button>
      </div>

      <div className="flex flex-col gap-y-6 w-full">
        <div className="flex items-center justify-between ">
          <div className="flex flex-col">
            <div className="flex items-center justify-start my-5">
              <h1 className="text-2xl font-semibold text-ppa/title mr-8 w-full">
                {tender.shortId}
              </h1>
              <TenderPill status={tender.status} />
            </div>
          </div>
        </div>

        <div className={classNames('flex flex-col', '2xl:flex-row')}>
          <div className="flex flex-col gap-y-3 w-full">
            <h3 className="font-semibold text-lg text-ppa/title">
              {t('list.title')}
            </h3>

            <DefList rowKeys={rowKeysDetails} data={tender} />

            {buttons && (
              <div className="flex items-center justify-start gap-x-5 w-full mt-5">
                {buttons.map((item) => (
                  <Button
                    {...item.props}
                    key={item.text}
                    onClick={() => item.onClick()}
                  >
                    {item.text}
                  </Button>
                ))}
              </div>
            )}
          </div>

          <div
            className={classNames(
              'flex flex-col gap-y-4',
              'border-ppa/grayBorder',
              'pt-10',
              '2xl:border-t-0 2xl:border-l 2xl:mt-10 2xl:pt-0 2xl:ml-10 2xl:pl-10',
            )}
          >
            <div className="flex flex-col gap-y-2">
              <h2 className="font-semibold text-lg text-ppa/title">
                {t('meterDetailsTitle')}
              </h2>
              <DefList
                rowKeys={rowKeysSiteDetails}
                data={tender.consumerMeter}
              />
            </div>

            <div className="flex items-end mt-10">
              <h1 className="text-lg font-medium text-ppa/title mr-3">
                {t('hhd.title')}
              </h1>
            </div>

            <CollapseList
              title={t('hhd.collapseList.title')}
              titleEmptyList={t('hhd.collapseList.filesNotFound')}
              list={
                hhdFiles && hhdFiles.length > 0
                  ? hhdFiles.map((file: any, index) => ({
                      id: `file-${index}`,
                      name: file.fileName,
                      blob: file,
                    }))
                  : []
              }
              defaultOpen
              rowKeys={[
                { keyName: 'name' },
                {
                  keyName: 'actions',
                  containerClass: 'ml-auto pl-2 max-w-[50px]',
                  renderCustomEl: (file: any) => {
                    return (
                      <div className="flex items-center gap-x-2">
                        <DownloadIcon
                          className="w-5 h-5 cursor-pointer hover:brightness-75 stroke-ppa/primary"
                          onClick={() => handleDownloadHHD(file)}
                        />
                      </div>
                    )
                  },
                },
              ]}
            />
            <div className="my-5">
              <DownloadHhd mpan={tender.consumerMeter?.mpan as string} />
            </div>
          </div>
        </div>
      </div>
      {selectedSupplier && (
        <SupplierDefListModal
          isOpen={isSupplierModalOpen}
          onClose={() => setIsSupplierModalOpen(false)}
          title={selectedSupplier.name}
          supplierProfileData={selectedSupplier.profile as any}
        />
      )}
      {tender.consumerMeter.company && (
        <CompanyDefListModal
          isOpen={isCompanyModalOpen}
          onClose={() => setIsCompanyModalOpen(false)}
          title={tender.consumerMeter.company.name}
          companyData={tender.consumerMeter.company}
        />
      )}
    </section>
  )
}

export default TenderDetails
