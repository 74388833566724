import TendersActive from './active'
import TendersActiveDetails from './active/details'

import TendersDraft from './draft'
import TendersDraftDetails from './draft/details'

import TendersPending from './pending'
import PendingTenderDetails from './pending/details'

import TendersExecuted from './executed'
import TendersExecutedDetails from './executed/details'

import TendersWithdrawn from './withdrawn'
import TendersWithdrawnDetails from './withdrawn/details'

import TendersUnderOffer from './under-offer'
import TendersUnderOfferDetails from './under-offer/details'

export const consumerTendersLinks = [
  { to: '/status/active', label: 'tenderStatus.active' },
  { to: '/status/draft', label: 'tenderStatus.draft' },
  { to: '/status/pending', label: 'tenderStatus.pending' },
  { to: '/status/executed', label: 'tenderStatus.executed' },
  { to: '/status/withdrawn', label: 'tenderStatus.withdrawn' },
  { to: '/status/underOffer', label: 'tenderStatus.underOffer' },
]

export const consumerTendersSubRoutes = {
  active: {
    index: <TendersActive />,
    details: TendersActiveDetails,
  },
  draft: {
    index: <TendersDraft />,
    details: TendersDraftDetails,
  },
  pending: {
    index: <TendersPending />,
    details: PendingTenderDetails,
  },
  executed: {
    index: <TendersExecuted />,
    details: TendersExecutedDetails,
  },
  underOffer: {
    index: <TendersUnderOffer />,
    details: TendersUnderOfferDetails,
  },
  withdrawn: {
    index: <TendersWithdrawn />,
    details: TendersWithdrawnDetails,
  },
}
