import React, { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import usePPA from '../../../../../../hooks/use-ppa'

import { feedbackMessage } from '../../../../../../components/atoms/feedback'
import { SelectOptions } from '../../../../../../components/atoms/select'
import SlidingDrawer from '../../../../../../components/molecules/sliding-drawer'

import { TenderMeterWithInvitation } from '../../../../../../types'
import {
  dateFormats,
  priceTypeConsumerTenderList,
} from '../../../../../../utils/data'

import TenderForm, {
  optionsDurations,
  targetMatchOptions,
  FormValues,
} from '../form-tender'

interface EditTenderProps {
  tender: TenderMeterWithInvitation
  isDrawerOpen: boolean
  handleCloseDrawer: () => void
  onSuccess: () => void
}

const EditTender: React.FC<EditTenderProps> = ({
  tender,
  onSuccess,
  isDrawerOpen,
  handleCloseDrawer,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'consumers.tenders',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()
  const [isLoading, setIsLoading] = useState(false)

  const paymentMethodOptions = [
    {
      value: 'DIRECT_DEBIT',
      label: t(
        'drawerCreateUpdateTender.form.paymentMethod.options.option1.label',
      ),
    },
    {
      value: 'BACS',
      label: t(
        'drawerCreateUpdateTender.form.paymentMethod.options.option2.label',
      ),
    },
  ]

  const tenderFormData = useMemo((): any => {
    if (!tender) return undefined

    const {
      startDate,
      endDate,
      preferredContractType,
      paymentMethodName,
      greenTariff,
      targetMatch,
      otherRequirements,
    } = tender

    const matchingContractTypeOption = priceTypeConsumerTenderList.find(
      (option) => option.value === preferredContractType,
    )

    const matchingTargetMatchOption =
      tender.preferredContractType === 'SLEEVED'
        ? targetMatchOptions.find(
            (option: any) => option.value === targetMatch,
          ) || { value: targetMatch, label: '' }
        : null

    const matchingPaymentMethodOption = paymentMethodOptions.find(
      (option) => option.value === paymentMethodName,
    )

    return {
      startDate: dayjs(startDate).format(dateFormats.aws),
      endDate: dayjs(endDate).format(dateFormats.aws),
      durationMonths: optionsDurations[0],
      customEndDate: true,
      meter: [
        {
          value: JSON.stringify(tender.consumerMeter),
          label: tender?.consumerMeter?.name,
        },
      ],
      contractType: matchingContractTypeOption
        ? {
            label: tUtils(
              `priceTypeConsumer.${matchingContractTypeOption.label}`,
            ),
            value: preferredContractType,
          }
        : undefined,
      greenTariff,
      targetMatch: matchingTargetMatchOption,
      paymentMethod: matchingPaymentMethodOption
        ? {
            label: matchingPaymentMethodOption.label,
            value: matchingPaymentMethodOption.value,
          }
        : undefined,
      participatingSuppliers: tender.consumerTenderInvitations.map((item) => {
        return {
          value: JSON.stringify(item.supplier),
          label: item.supplier.name,
        }
      }),

      otherRequirements: otherRequirements || '',
    }
  }, [tender])

  const handleEdit = async (formData: FormValues) => {
    if (formData.meter.length > 1) {
      feedbackMessage(
        {
          title: tUtils('feedbackMessage.error.title'),
          description: t(
            'drawerCreateUpdateTender.form.edit.onSubmit.error.moreThanOneMeter',
          ),
        },
        'error',
      )
      return
    }

    if (isLoading || !tender) return
    setIsLoading(true)

    try {
      let supplierIds: string[] = []

      if (
        formData.participatingSuppliers &&
        formData.participatingSuppliers.length > 0
      ) {
        supplierIds = formData.participatingSuppliers.map(
          (item: SelectOptions) => JSON.parse(item.value as string).id,
        )
      }

      const {
        startDate,
        durationMonths,
        customEndDate,
        otherRequirements,
        contractType,
        paymentMethod,
        targetMatch,
        greenTariff,
      } = formData

      let { endDate } = formData
      if (customEndDate) {
        endDate = formData.endDate
      } else {
        endDate = dayjs(startDate)
          .add(durationMonths?.value as any, 'months')
          .subtract(1, 'month')
          .endOf('month')
          .format(dateFormats.aws)
      }

      const { response, error: err } = await fetchData({
        method: 'PUT',
        url: `/core/private/consumer-tender/consumer/${tender.id}`,
        body: {
          startDate,
          endDate,
          consumerMeterId: JSON.parse(formData.meter[0].value as string).id,
          preferredContractType: contractType?.value,
          greenTariff,
          paymentMethod: paymentMethod?.value,
          otherRequirements,
          targetMatch:
            contractType?.value === 'SLEEVED' ? targetMatch?.value : null,
          supplierIds,
        },
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: t('drawerCreateUpdateTender.form.edit.onSubmit.success.title'),
          description: t(
            'drawerCreateUpdateTender.form.edit.onSubmit.success.description',
          ),
        },
        'success',
      )
      onSuccess()
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'consumerTender',
        NOT_FOUND_ERROR: ({ message }) => {
          if (message.includes('Meters not found')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.consumerTender.NOT_FOUND_ERROR.METERS_NOT_FOUND',
              ),
              type: 'error',
            }
          }
          if (message.includes('No Tender found')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.consumerTender.NOT_FOUND_ERROR.update.notFound',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <SlidingDrawer isOpen={isDrawerOpen} handleClose={handleCloseDrawer}>
      <div className="flex flex-col gap-y-5 sm:w-[640px]">
        <h1 className="text-xl font-bold text-ppa/title">
          {t('drawerCreateUpdateTender.form.edit.title')}
        </h1>

        {isDrawerOpen && (
          <TenderForm
            isLoading={isLoading}
            onSubmit={handleEdit}
            onCancel={handleCloseDrawer}
            defaultValues={tenderFormData}
          />
        )}
      </div>
    </SlidingDrawer>
  )
}

export default EditTender
