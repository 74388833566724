import React, { useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { TrashIcon } from '@heroicons/react/outline'

import usePPAGetList from '../../../../../hooks/use-ppa/use-ppa-get-list'
import useUser from '../../../../../hooks/use-user'
import usePPA from '../../../../../hooks/use-ppa'

import { Member } from '../../../../../types'

import { ReactComponent as UserIcon } from './assets/User.svg'
import Message from '../../../../../components/atoms/message'
import Table, { TableConfigProps } from '../../../../../components/atoms/table'
import Dropdown from '../../../../../components/atoms/dropdown'
import { feedbackMessage } from '../../../../../components/atoms/feedback'

import InviteUser from './invite-user-to-organisation'

const rowKeys: TableConfigProps[] = [
  {
    keyName: 'name',
    renderCustomEl: (member: Member) => {
      if (member.firstName || member.lastName || member.email) {
        return (
          <div className="flex">
            <UserIcon />
            <div className="ml-6 font-normal">
              {member.firstName} {member.lastName}
              <div className="font-light">{member?.email}</div>
            </div>
          </div>
        )
      }
      return undefined
    },
  },
  {
    keyName: 'phoneNumber',
  },
]

const Users: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'settings.users',
  })

  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { user } = useUser<'SUPPLIER'>()
  const { memberType } = user.appMetadata

  const { fetchData, throwFeedbackError } = usePPA()

  const {
    isLoading,
    reset,
    throwFeedbackError: membersThrowFeedbackError,
    data,
    error,
  } = usePPAGetList<Member>({
    dataKey: 'members',
    path: '/core/private/supplier/member/list',
  })

  const usersInOrganisation = useMemo(() => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, isLoading, error])

  const handleDeleteMember = async (member: Member) => {
    if (!usersInOrganisation || usersInOrganisation[0].deleted) return

    try {
      const { error: respError, response } = await fetchData<{
        message: string
      }>({
        method: 'DELETE',
        url: `/core/private/supplier/delete-member/${member.id}`,
      })

      if (respError || !response) {
        throw respError
      }

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('handleDeleteMember.success.description'),
        },

        'success',
      )
      reset()
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'supplier',
        AUTHORIZATION_ERROR: ({ message }) => {
          if (message?.includes('Invalid member type')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.supplier.reason.INVALID_MEMBER_TYPE',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    }
  }

  useEffect(() => {
    if (error) {
      membersThrowFeedbackError({ err: error })
    }
  }, [error])

  return (
    <div className="max-w-[800px] overflow-auto">
      <div className="ml-3">
        <Message variant="info" size="text-xs self-center">
          {t('usersInOrganisationInfoMessage')}
        </Message>
        <div className="my-5 flex items-center">
          <h1 className="text-xl font-bold text-ppa/title">{t('pageTitle')}</h1>
          {memberType !== 'REGULAR' && <InviteUser onSuccess={reset} />}
        </div>
        <Table
          headers={[
            t('table.headers.userDetails'),
            t('table.headers.phoneNumber'),
            t('table.headers.memberType'),
            '',
          ]}
          rowKeys={[
            ...rowKeys,
            {
              keyName: 'memberType',
              renderCustomEl: (member: Member) =>
                t(`table.body.memberType.${member.memberType}`),
            },
            {
              keyName: 'action',
              renderCustomEl: (member: Member) => {
                if (memberType !== 'REGULAR' && member.email !== user.email) {
                  return (
                    <Dropdown
                      orientation="bottom-left"
                      content={
                        <button
                          type="button"
                          onClick={() => handleDeleteMember(member)}
                          className="flex items-center w-24"
                        >
                          <TrashIcon className="w-4 h-4 mr-2.5" />
                          {t('deleteButton')}
                        </button>
                      }
                    />
                  )
                }
                return <span />
              },
            },
          ]}
          isLoading={isLoading}
          data={usersInOrganisation}
        />
      </div>
    </div>
  )
}

export default Users
