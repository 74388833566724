import { Navigate, Route } from 'react-router-dom'

import { isFeatureEnabled } from '../../../utils/features'

import { ReactComponent as SiteSVG } from '../assets/Site.svg'
import { ReactComponent as TenderSVG } from '../assets/Tender.svg'
import { ReactComponent as MarketSVG } from '../assets/Market.svg'

import { metersLinks, metersSubRoutes } from './meters/routes'

import ConsumerTenders from './tenders'
import ConsumerTenderDetails from './tenders/details'
import {
  consumerTendersLinks,
  consumerTendersSubRoutes,
} from './tenders/routes'

import Market from '../market'
import { LinksArray } from '../routes'
import NotFound from '../not-found'
import Notifications from '../notifications'
import Settings from './settings'

export const consumerLinks: LinksArray = [
  {
    to: '/meters',
    label: 'meters',
    icon: SiteSVG,
    subMenu: metersLinks,
  },
  {
    to: '/tenders',
    label: 'tenders',
    icon: TenderSVG,
    subMenu: consumerTendersLinks,
  },
  { to: '/market', label: 'market', icon: MarketSVG },
]

export const consumerRoutes = [
  { path: '/', element: <Navigate to="/meters" /> },
  {
    path: '/meters',
    children: (
      <>
        <Route index element={<Navigate to="/meters/portfolio" />} />
        <Route path="portfolio" element={metersSubRoutes.portfolio.index} />
        <Route path=":meterId" element={metersSubRoutes.portfolio.details} />
        <Route path="companies" element={metersSubRoutes?.companies?.index} />
        <Route
          path="/meters/companies/:companyId"
          element={metersSubRoutes?.companies?.details}
        />
        <Route path="*" element={<NotFound />} />
      </>
    ),
  },
  {
    path: '/tenders',
    children: (
      <>
        <Route index element={<Navigate to="/tenders/status/active" />} />
        {Object.keys(consumerTendersSubRoutes).map((key: any) => (
          <Route key={key}>
            <Route
              path={`/tenders/status/${key}`}
              element={<ConsumerTenders status={key} />}
            />
            <Route
              path="/tenders/:tenderId"
              element={<ConsumerTenderDetails />}
            />
          </Route>
        ))}
        <Route path="*" element={<NotFound />} />
      </>
    ),
  },
  { path: '/market', element: <Market /> },
  { path: '/settings', element: <Settings /> },
]

if (isFeatureEnabled('notificationsPage')) {
  consumerRoutes.push({
    path: '/notifications',
    element: <Notifications />,
  })
}
