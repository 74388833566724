import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { calculator } from '@ppayauk/ppaya-slider-js/dist/Calculator'
import {
  RequestParams,
  PriceSpec,
} from '@ppayauk/ppaya-slider-js/dist/Calculator/types'
import { tableLogger } from '@ppayauk/ppaya-slider-js/dist/Logger/tableLogger'
import { tff } from '@ppayauk/ppaya-slider-js/dist/Calculator/TF'

import usePPA from '../../../../../../hooks/use-ppa'

import InputGroup from '../../../../../../components/molecules/input-group'

import Input from '../../../../../../components/atoms/input'
import Slider from '../../../../../../components/atoms/slider'
import Message from '../../../../../../components/atoms/message'

import { ResultSlider } from './slider'

export interface SliderParams {
  starting_price: number
  weight: number
  volatility: number
  drift: number
}

interface TargetPriceProps {
  quotePayload: any
  daysUntilTenderStarts?: number
  name: string
  onChange: (value: any) => void
  value: string
  error?: string
}

const TargetPrice: React.FC<TargetPriceProps> = ({
  quotePayload,
  daysUntilTenderStarts,
  name,
  onChange,
  value,
  error,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.tenders.drawerTargetPrice',
  })

  const { fetchData } = usePPA()

  const [sliderData, setSliderData] = useState<ResultSlider | undefined>()

  const minMaxSlider = useMemo((): [number, number] | undefined => {
    if (!sliderData) return undefined

    return [
      parseFloat(sliderData.prices[0]) * 10,
      parseFloat(sliderData.prices.at(-1) as string) * 10,
    ]
  }, [sliderData])

  const label = useMemo(() => {
    if (!value) return '0.00'

    return parseFloat(value).toFixed(2)
  }, [value])

  const probability = useMemo(() => {
    const defaultResult = ' - '
    if (!label || label === '0.00' || !sliderData) return defaultResult

    const goal = parseFloat(label)
    const [closest, closestIdx] = sliderData.prices.reduce(
      (prev, current, index) => {
        let previous: [number, number] = prev
        if (typeof prev === 'number') {
          previous = [parseFloat(prev[0]), 0]
        }
        const parsedCurrent = parseFloat(current)
        return Math.abs(parsedCurrent - goal) < Math.abs(previous[0] - goal)
          ? [parsedCurrent, index]
          : prev
      },
      [0, 0],
    )

    return sliderData.probabilities[closestIdx]
  }, [label])

  const getProbability = useCallback(async (): Promise<void> => {
    try {
      const { response, error: err } = await fetchData({
        method: 'POST',
        url: '/quote/private/quote',
        body: {
          ...quotePayload,
          renderPerSeason: false,
        },
      })

      if (err || !response) throw err

      // let lowestPrice: number | undefined
      /* const tenderParams = response.data.quote.seasons.map(
        (item: any): PriceSpec => {
           const thisPrice =
            item.pricing.averaged.summary.finalPrice +
            (item.pricing.averaged.fees?.imbalance?.guaranteed || 0)

          const thisPrice = item.pricing.averaged.summary.finalPrice
          // - item.pricing.averaged.summary.fees

          console.log(`${item.season} price`, thisPrice)

          if (!lowestPrice || thisPrice < lowestPrice) {
            lowestPrice = thisPrice
          }
          return {
            startingPrice: thisPrice,
            dailyDrift: item.drift,
            weight: item.weight,
            dailyVolatility: item.volatility,
          }
        },
      ) */

      const tenderParams = [
        {
          startingPrice:
            response.data.quote.pricing.averaged.summary.finalPrice,
          dailyDrift: -0.01,
          weight: 1,
          dailyVolatility: 0.1,
        },
      ]

      /* console.log('tenderParams', tenderParams)

      if (!lowestPrice) {
        throw new Error('Something went wrong while generating slider params.')
      }

      lowestPrice *= 0.6 */

      if (!daysUntilTenderStarts || daysUntilTenderStarts <= 0) {
        throw new Error(
          'Can not generate slider in a tender with remaining days.',
        )
      }

      const params: RequestParams = {
        days: daysUntilTenderStarts,
        targetStep: 0.5,
        samples: 10000,
        tenderSpec: tenderParams,
      }

      const [_, ...otherAssumptions] = await calculator(params)(
        tff(),
        tableLogger(),
      )

      const sliderAssumptions = otherAssumptions as [string, number][]

      const newSliderChartData: ResultSlider = {
        prices: [],
        probabilities: [],
      }

      // console.log('sliderAssumptions', sliderAssumptions)

      sliderAssumptions
        .filter(([sliderValue, prob]) => {
          return !(prob >= 95 || prob <= 40)
        })
        .forEach(([price, prob]) => {
          newSliderChartData.prices.push(price)
          newSliderChartData.probabilities.push(prob)
        })

      // console.log('sliderAssumptions', sliderAssumptions)

      setSliderData(newSliderChartData)
      return
    } catch (err: any) {
      console.error(err)
    }

    setSliderData({
      prices: [],
      probabilities: [],
    })
  }, [quotePayload])

  useEffect(() => {
    if (sliderData || !quotePayload) return

    getProbability()
  }, [sliderData, quotePayload])

  return (
    <div className="flex flex-col gap-y-4">
      <div className="w-full flex flex-col">
        <InputGroup label={t('targetPrice.price')} required error={error}>
          <Message variant="info" size="text-xs self-center">
            {t('targetPrice.infoMessage')}
          </Message>
          <div className="max-w-[275px] mt-2">
            <Input
              type="text"
              placeholder="0.0"
              name={name}
              onChange={onChange}
              value={value}
              suffix="£/MWh"
              error={error}
              data-testid="targetPrice"
            />
          </div>
          <span className="text-sm text-ppa/title font-medium mt-1 mb-5">
            {t('targetPrice.price')}:&nbsp;£{label}
            &nbsp;/&nbsp;MWh&nbsp;(Probability:&nbsp;{probability}%)
          </span>
        </InputGroup>
      </div>

      {minMaxSlider && (
        <input
          type="range"
          name={name}
          className="w-full mb-2"
          min={minMaxSlider[0]}
          max={minMaxSlider[1]}
          step={2}
          value={parseFloat(value) * 10}
          onChange={(e: any) => {
            const parsedValue = parseFloat(
              (e.target.value / 10) as any,
            ).toFixed(2)
            onChange(parseFloat(parsedValue))
          }}
        />
      )}

      {sliderData &&
        sliderData?.prices.length > 0 &&
        sliderData?.probabilities.length > 0 && (
          <Slider data={sliderData as any} />
        )}
    </div>
  )
}

export default TargetPrice
