import React from 'react'
import VerticalTab from '../../../../components/atoms/vertical-tab'

import Notifications from './notifications'

const Settings: React.FC = () => {
  return (
    <VerticalTab
      header="Settings"
      tabs={[
        {
          label: 'Notifications',
          content: <Notifications />,
        },
      ]}
    />
  )
}

export default Settings
