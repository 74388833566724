/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { ChevronLeftIcon, DownloadIcon } from '@heroicons/react/outline'
import classNames from 'classnames'

import usePPA from '../../../../../../hooks/use-ppa'

import {
  parseSnippetAddress,
  parseContractDuration,
} from '../../../../../../utils'

import { container, breakPointsContainer } from '../../../../../../styles'

import {
  dateFormats,
  mappedMatchPercentage,
} from '../../../../../../utils/data'

import Button, {
  Props as ButtonProps,
} from '../../../../../../components/atoms/button'
import DefList from '../../../../../../components/atoms/def-list'
import Dropdown from '../../../../../../components/atoms/dropdown'
import Message from '../../../../../../components/atoms/message'

import CollapseList from '../../../../../../components/molecules/collapse-list'
import DownloadHhd from '../../../../../../components/organisms/latest-hhd-download'

import SupplierDefListModal from '../../../../../../components/organisms/supplier-details-modal'
import CompanyDefListModal from '../../../../../../components/organisms/company-details-modal'

import TenderPill from '../../../../components/tender-pill'

import {
  TenderMeterWithInvitation,
  TenderStatus,
  Meter,
  Supplier,
  SupplierProfile,
} from '../../../../../../types'

import { mappedConsumerTenderStatus } from '../../../types'
import { parseQuery } from '../../../../../../hooks/use-ppa/use-ppa-get-list'

dayjs.extend(utc)
dayjs.extend(timezone)

type HhdFile = Blob & {
  fileName: string
}

type ParticipatingSupplier = Supplier & {
  profile: SupplierProfile
}

export type TenderInvitation = TenderMeterWithInvitation & {
  bids?: any[]
}

export interface ButtonList {
  props: ButtonProps
  text: string
  onClick: () => void
}

interface TenderDetailsProps {
  tender: TenderInvitation
  buttons?: ButtonList[]
  actionsDropdown?: ButtonList[]
  hasLoa?: boolean
}

const TenderDetails: React.FC<TenderDetailsProps> = ({
  buttons,
  tender,
  actionsDropdown,
  hasLoa,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'consumers.tenders.details',
  })

  const navigate = useNavigate()

  const { fetchData, throwFeedbackError } = usePPA()

  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false)
  const [isSupplierModalOpen, setIsSupplierModalOpen] = useState(false)
  const [selectedSupplier, setSelectedSupplier] =
    useState<ParticipatingSupplier | null>(null)

  const [isLoadingHhdFiles, setIsLoadingHhdFiles] = useState(false)
  const [hhdFiles, setHhdFiles] = useState<HhdFile[] | undefined>()
  const [isDownloadingHhdFiles, setIsDownloadingHhdFiles] = useState(false)

  const rowKeysDetails = useMemo(() => {
    const details = [
      {
        keyName: 'contractDuration',
        title: t('list.titles.contractDuration'),
        renderCustomEl: (item: TenderInvitation) => {
          return parseContractDuration(item.startDate, item.endDate)
        },
      },
      {
        keyName: 'deadline',
        title: t('list.titles.deadline'),
        renderCustomEl: (item: TenderInvitation) => {
          if (!item?.deadline) return undefined

          return dayjs
            .utc(item.deadline)
            .tz('Europe/London')
            .format(dateFormats.userDateTime)
        },
      },
      ...(tender.preferredContractType === 'SLEEVED'
        ? [
            {
              keyName: 'targetMatch',
              title: t('list.titles.targetMatch'),
              renderCustomEl: (item: TenderInvitation) => {
                if (!item?.targetMatch) return undefined
                return mappedMatchPercentage[item.targetMatch]
              },
            },
          ]
        : []),
      {
        keyName: 'greenTariff',
        title: t('list.titles.greenTariff'),
        renderCustomEl: (item: TenderInvitation) => {
          if (!item?.greenTariff) return undefined
          return item.greenTariff ? 'Yes' : 'No'
        },
      },
      {
        keyName: 'participatingSuppliers',
        title: t('list.titles.participatingSuppliers'),
        renderCustomEl: (item: TenderInvitation) => {
          if (!item?.consumerTenderInvitations) return undefined

          const suppliers = item.consumerTenderInvitations.map(
            (invitation) => invitation.supplier,
          )

          const supplierNames = suppliers
            .map((supplier) => supplier.name)
            .join(', ')

          return (
            <div className="cursor-pointer">
              {suppliers.length
                ? suppliers.map((supplier, index) => (
                    <span
                      key={supplier.id}
                      onClick={() => {
                        setSelectedSupplier(supplier as any)
                        setIsSupplierModalOpen(true)
                      }}
                      className="border-b border-ppa/grayText cursor-pointer"
                    >
                      {supplierNames}
                    </span>
                  ))
                : '-'}
            </div>
          )
        },
      },
      {
        keyName: 'brokerFee',
        title: t('list.titles.brokerFee'),
        suffix: '£/MWh',
        renderCustomEl: (item: TenderInvitation) => {
          if (!item?.consumerMeter?.brokerFee) return undefined
          return item.consumerMeter.brokerFee
        },
      },
    ]

    return details
  }, [tender])

  const rowKeysSiteDetails = useMemo(() => {
    return [
      {
        keyName: 'name',
        title: t('meterDetailsList.titles.name'),
        renderCustomEl: (meter: Meter) => {
          return meter.name
        },
      },
      {
        keyName: 'mpan',
        title: t('meterDetailsList.titles.mpan'),
        renderCustomEl: (meter: Meter) => {
          if (!meter.mpan) return undefined
          return meter.mpan
        },
      },
      {
        keyName: 'hhd',
        title: t('meterDetailsList.titles.hhd'),
        renderCustomEl: (meter: Meter) => {
          if (!meter.hhdTimeFrame) return undefined
          return meter.hhdTimeFrame
        },
      },
      {
        keyName: 'annualConsumption',
        title: t('meterDetailsList.titles.annualConsumption'),
        suffix: 'MWh',
        renderCustomEl: (meter: Meter) => {
          if (!meter.annualConsumption) return undefined
          return meter.annualConsumption
        },
      },
      {
        keyName: 'gridConnectionSize',
        title: t('meterDetailsList.titles.gridConnectionSize'),
        suffix: 'kVA',
        renderCustomEl: (meter: Meter) => {
          if (!meter.gridConnectionSize) return undefined
          return meter.gridConnectionSize
        },
      },
      {
        keyName: 'company',
        title: t('meterDetailsList.titles.company.title'),
        renderCustomEl: (meter: Meter) => {
          if (!meter.company) return undefined
          return (
            <div className="flex flex-col">
              <div
                onClick={() => {
                  setIsCompanyModalOpen(true)
                }}
                className="border-b border-ppa/grayText cursor-pointer w-fit"
              >
                {meter.company.name}
              </div>
              {meter.company.number && (
                <div
                  onClick={() => {
                    setIsCompanyModalOpen(true)
                  }}
                  className="border-b border-ppa/grayText cursor-pointer w-fit"
                >
                  {meter.company.number}
                </div>
              )}
              {meter.company.address && (
                <div
                  onClick={() => {
                    setIsCompanyModalOpen(true)
                  }}
                  className="border-b border-ppa/grayText cursor-pointer w-fit"
                >
                  {parseSnippetAddress(meter.company.address as any)}
                </div>
              )}
            </div>
          )
        },
      },
      {
        keyName: 'existingSupplier',
        title: t('meterDetailsList.titles.existingSupplier'),
        renderCustomEl: (meter: Meter) => {
          if (!meter.existingSupplier) return undefined
          return meter.existingSupplier
        },
      },
    ]
  }, [tender?.consumerMeter])

  const handleFetchHhdFiles = async () => {
    if (isLoadingHhdFiles) return
    setIsLoadingHhdFiles(true)

    try {
      const { response, error: fetchError } = await fetchData({
        method: 'GET',
        url: `/core/private/consumer-meter/consumer/hhd/list?${parseQuery({
          meterId: tender?.consumerMeter?.id,
        })}`,
        responseType: 'json',
      })

      if (fetchError) throw fetchError

      const files = response?.data.hhdFiles || []

      const processedFiles = await Promise.all(
        files.map(async (fileObject: { file: string; fileName: string }) => {
          const base64Data = fileObject.file || ''
          const fileNameFromResponse =
            fileObject.fileName || 'defaultFileName.pdf'

          const binaryResponse = await fetch(
            `data:application/octet-stream;base64,${base64Data}`,
          )

          const binaryData = await binaryResponse.blob()

          const blob = new Blob([binaryData], {
            type: 'application/octet-stream',
          })

          return { blob, fileName: fileNameFromResponse }
        }),
      )

      setHhdFiles(processedFiles)
    } catch (err: any) {
      throwFeedbackError(err)
    } finally {
      setIsLoadingHhdFiles(false)
    }
  }

  const handleDownloadHhd = async (file: any) => {
    if (isDownloadingHhdFiles) return

    try {
      const { response, error: downloadError } = await fetchData({
        method: 'GET',
        url: `/core/private/consumer-meter/consumer/hhd?${parseQuery({
          meterId: tender?.consumerMeter?.id,
          fileName: file.blob.fileName,
        })}`,
        responseType: 'json',
      })

      if (downloadError) throw downloadError

      const base64Data = response?.data.hhdFile

      const binaryResponse = await fetch(
        `data:application/octet-stream;base64,${base64Data}`,
      )
      const binaryData = await binaryResponse.blob()

      const blob = new Blob([binaryData], {
        type: 'application/octet-stream',
      })

      const url = URL.createObjectURL(blob)

      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.setAttribute('download', file.blob.fileName)
      downloadLink.target = '_blank'
      downloadLink.click()

      URL.revokeObjectURL(url)
    } catch (err) {
      throwFeedbackError({ err })
    } finally {
      setIsDownloadingHhdFiles(false)
    }
  }

  useEffect(() => {
    handleFetchHhdFiles()
  }, [])

  return (
    <section
      className={classNames(
        container,
        breakPointsContainer,
        'items-center pt-12 relative gap-y-8',
      )}
    >
      <div className="absolute top-0 left-0 -mx-3 hover:brightness-50 cursor-pointer">
        <Button
          variant="text"
          icon={<ChevronLeftIcon />}
          onClick={() =>
            navigate(
              `/tenders/status/${
                mappedConsumerTenderStatus[tender.status as TenderStatus]
              }`,
            )
          }
        >
          {t('backButton')}
        </Button>
      </div>

      <div className="flex flex-col gap-y-6 w-full">
        <div>
          <div className="flex items-center justify-between ">
            <div className="flex flex-col">
              <div className="flex items-center justify-start my-5">
                <h1 className="text-2xl font-semibold text-ppa/title mr-8 w-full">
                  {tender.shortId}
                </h1>
                <TenderPill status={tender.status as TenderStatus} />
              </div>
            </div>

            {actionsDropdown && (
              <Dropdown
                content={
                  <div className="flex flex-col">
                    {actionsDropdown &&
                      actionsDropdown.map((item) => (
                        <Button
                          {...item.props}
                          key={item.text}
                          onClick={() => item.onClick()}
                        >
                          {item.text}
                        </Button>
                      ))}
                  </div>
                }
              />
            )}
          </div>

          {!hasLoa &&
            (tender.status === 'DRAFT' || tender.status === 'WITHDRAWN') && (
              <div className="max-w-[550px]">
                <Message variant="warn" size="text-xs">
                  {t('warnMessageTenderWithNoLoa')}
                </Message>
              </div>
            )}
        </div>

        <div className={classNames('flex flex-col', '2xl:flex-row')}>
          <div className="flex flex-col gap-y-3 max-w-[550px]">
            <h3 className="font-semibold text-lg text-ppa/title">
              {t('list.title')}
            </h3>

            <DefList rowKeys={rowKeysDetails} data={tender} />

            {buttons && (
              <div className="flex items-center justify-start gap-x-5 w-full mt-5">
                {buttons.map((item) => (
                  <div className="w-full flex max-w-[165px]" key={item.text}>
                    <Button {...item.props} onClick={() => item.onClick()}>
                      {item.text}
                    </Button>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div
            className={classNames(
              'flex flex-col flex-1 gap-y-4',
              'border-ppa/grayBorder',
              'border-t mt-10 pt-10',
              '2xl:border-t-0 2xl:border-l 2xl:mt-10 2xl:pt-0 2xl:ml-10 2xl:pl-10',
            )}
          >
            <div className="flex flex-col gap-y-2 mb-8">
              <h2 className="font-semibold text-lg text-ppa/title">
                {t('meterDetailsTitle')}
              </h2>
              <DefList
                rowKeys={rowKeysSiteDetails}
                data={tender.consumerMeter}
              />
            </div>

            <div className="flex flex-col">
              <div className="flex items-end mb-5">
                <h1 className="text-lg font-medium text-ppa/title mr-3">
                  {t('hhd.title')}
                </h1>
              </div>
              <CollapseList
                title={t('hhd.collapseList.title')}
                titleEmptyList={t('hhd.collapseList.filesNotFound')}
                list={
                  hhdFiles && hhdFiles.length > 0
                    ? hhdFiles.map((file, index) => ({
                        id: `hhdFile-${index}`,
                        name: file.fileName,
                        blob: file,
                      }))
                    : []
                }
                defaultOpen
                rowKeys={[
                  { keyName: 'name' },
                  {
                    keyName: 'actions',
                    containerClass: 'ml-auto pl-2 max-w-[50px]',
                    renderCustomEl: (file: any) => {
                      return (
                        <div className="flex items-center gap-x-2">
                          <DownloadIcon
                            className="w-5 h-5 cursor-pointer hover:brightness-75 stroke-ppa/primary"
                            onClick={() => handleDownloadHhd(file)}
                          />
                        </div>
                      )
                    },
                  },
                ]}
              />
              <div className="my-5">
                <DownloadHhd mpan={tender.consumerMeter?.mpan as string} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedSupplier && (
        <SupplierDefListModal
          isOpen={isSupplierModalOpen}
          onClose={() => setIsSupplierModalOpen(false)}
          title={selectedSupplier.name}
          supplierProfileData={selectedSupplier.profile as any}
        />
      )}
      {tender?.consumerMeter?.company && (
        <CompanyDefListModal
          isOpen={isCompanyModalOpen}
          onClose={() => setIsCompanyModalOpen(false)}
          title={t('meterDetailsList.titles.company.defListTitle', {
            companyName: tender.consumerMeter.company.name,
          })}
          companyData={tender.consumerMeter.company}
        />
      )}
    </section>
  )
}

export default TenderDetails
