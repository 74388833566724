import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { ConsumerTenderDetailsProps } from '../details'

import { ButtonList } from '../components/bid-table'

import ConsumerTenderDetails from '../components/details'
import CreateOrUpdateBid from '../components/create-edit-bid'
import BidDetails from '../components/details-bid'
import SitesTables from '../components/table-sites'

const ConsumerTendersActiveDetails: React.FC<ConsumerTenderDetailsProps> = ({
  tenderInvitation,
  refetch,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.consumerTenders.details.bids',
  })

  const [isBidDrawerOpen, setIsBidDrawerOpen] = useState(false)

  const handleCloseDrawer = () => setIsBidDrawerOpen(false)
  const handleOnSuccessDrawer = () => {
    setIsBidDrawerOpen(false)
    refetch()
  }

  const buttons = useMemo((): ButtonList[] => {
    if (!tenderInvitation?.consumerTender) return []
    let buttonText: string

    if (tenderInvitation?.consumerTenderBid) {
      if (tenderInvitation.consumerTenderBid.status === 'ACTIVE') {
        buttonText = t('actions.updateBid.button')
      } else if (tenderInvitation.consumerTenderBid.status === 'REJECTED') {
        buttonText = t('actions.editBid.button')
      } else {
        return []
      }
    } else {
      buttonText = t('actions.placeBid.button')
    }

    return [
      {
        onClick: () => setIsBidDrawerOpen(true),
        props: {
          variant: 'primary',
        },
        text: buttonText,
      },
    ]
  }, [tenderInvitation])

  return (
    <>
      <div className="flex flex-col 2xl:flex-row">
        <div className="flex-1">
          <ConsumerTenderDetails tender={tenderInvitation} />
        </div>
        <div
          className={classNames(
            'border-ppa/grayBorder',
            'border-t mt-10 pt-10',
            '2xl:border-t-0 2xl:border-l 2xl:mt-10 2xl:pt-0 2xl:ml-10 2xl:pl-10',
            'flex-1',
          )}
        >
          <BidDetails
            consumerTenderInvitation={tenderInvitation}
            buttons={buttons}
            refetchBidList={refetch}
          />
        </div>
        {tenderInvitation && (
          <CreateOrUpdateBid
            isDrawerOpen={isBidDrawerOpen}
            tenderInvitation={tenderInvitation}
            onSuccess={handleOnSuccessDrawer}
            closeDrawer={handleCloseDrawer}
          />
        )}
      </div>
      <SitesTables tenderData={tenderInvitation.consumerTender} />
    </>
  )
}

export default ConsumerTendersActiveDetails
