/* eslint-disable import/prefer-default-export */

import { Tender } from '../../../../types'
import { tendersSubRoutes } from '../export-tenders/routes'
import { sleevedTendersSubRoutes } from '../sleeved-tenders/routes'

export const mappedTenderStatus: Record<
  Tender['status'],
  | keyof typeof tendersSubRoutes
  | keyof typeof sleevedTendersSubRoutes
  | 'archived'
> = {
  ACTIVE: 'active',
  UNDER_OFFER: 'underOffer',
  EXECUTED: 'executed',
  DRAFT: 'draft',
  WITHDRAWN: 'withdrawn',
  ARCHIVED: 'archived',
  PENDING: 'pending',
}
