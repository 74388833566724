import React from 'react'

import classNames from 'classnames'

import { SleevedTenderDetailsProps } from '../details'

import TenderDetails from '../components/details'

import SleevedConsumerTenderDetailsDefList from '../components/details-consumer-tender'
import MeterDetailsDefList from '../components/details-meter'

const PendingSleevedTenderDetails: React.FC<SleevedTenderDetailsProps> = ({
  tenderInvitation,
}) => {
  return (
    <section className="flex flex-col 2xl:flex-row">
      <div className="flex-1">
        <TenderDetails tenderInvitation={tenderInvitation} />
      </div>
      <div className={classNames('flex flex-col', '2xl:flex-row')}>
        <div
          className={classNames(
            'flex flex-col flex-1 gap-y-4',
            'border-ppa/grayBorder',
            'border-t mt-10 pt-10',
            '2xl:border-t-0 2xl:border-l 2xl:mt-10 2xl:pt-0 2xl:ml-10 2xl:pl-10',
          )}
        >
          <div>
            <SleevedConsumerTenderDetailsDefList
              data={tenderInvitation.supplierTender.consumerTender as any}
            />
            <MeterDetailsDefList
              data={
                tenderInvitation.supplierTender.consumerTender
                  .consumerMeter as any
              }
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default PendingSleevedTenderDetails
