import React, { ButtonHTMLAttributes, ReactElement } from 'react'
import classNames from 'classnames'

import { ReactComponent as Spinner } from '../../../assets/spinner/spinner.svg'
import { Children } from '../../../types'
import { transition } from '../../../styles'

export const variants = {
  primary: 'bg-ppa/primary text-white border-2 border-ppa/primary',
  secondary:
    'bg-ppa/secondaryButtonBackground text-ppa/primary border-2 border-ppa/primary',
  tertiary:
    'bg-transparent text-ppa/secondary border-solid border-2 border-ppa/secondary',
  tertiaryActive:
    'bg-ppa/secondaryActive text-ppa/primary border-solid border-2 border-ppa/primary',
  quaternary: 'bg-ppa/quaternaryButtonBackground text-ppa/title shadow-md',
  text: 'bg-transparent text-ppa/secondary',
  quinary:
    'bg-ppa/sidebarButtonIcon text-white border-solid border-2 border-ppa/sidebarButtonIcon',
  filter:
    'bg-transparent text-ppa/secondary border-solid border border-ppa/filterBorder',
  filterActive:
    'bg-transparent text-ppa/secondary border-solid border border-filterBorderActive',
}

export interface Props
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    Children {
  variant: keyof typeof variants
  icon?: React.ReactNode
  fullWidth?: boolean
  formWidth?: boolean
  loading?: boolean
  rounded?: boolean
  paddingX?: boolean
  dropdownLabel?: boolean
}

const Button: React.FC<Props> = ({
  children,
  variant,
  onClick,
  icon,
  fullWidth = false,
  formWidth = false,
  loading = false,
  disabled = false,
  rounded,
  paddingX = true,
  dropdownLabel = false,
  ...restProps
}) => {
  const FormattedIcon = icon
    ? React.cloneElement(icon as ReactElement, {
        className: 'w-4 h-4 mr-2 self-center',
      })
    : undefined
  return (
    <button
      type="button"
      {...restProps}
      className={classNames(
        transition,
        'h-8 rounded-md disabled:cursor-not-allowed disabled:opacity-50 flex items-center justify-center text-sm font-medium',
        variants[variant],
        fullWidth && 'w-full',
        rounded && 'rounded-full',
        paddingX && 'px-3',
        formWidth && 'w-40',
      )}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading ? (
        <div className="w-16 min-w-full">
          <Spinner className="mx-auto animate-spin w-5 h-5" />
        </div>
      ) : (
        <span
          className={classNames(
            dropdownLabel ? 'text-xs font-normal' : 'text-sm font-medium',
            'flex text-center content-center',
          )}
        >
          {icon && FormattedIcon}
          {children}
        </span>
      )}
    </button>
  )
}

export default Button
