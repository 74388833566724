import dayjs, { Dayjs } from 'dayjs'
import * as countries from 'iso-3166-1'

import { dateFormats, embeddedBenefitsList } from './data'
import { Address } from '../types'

/**
 * @FIXME
import { Site, TenderPeriod } from '../types'
 */
type Site = any
type TenderPeriod = any

type SimulateRequest = (
  result: any,
  timeout?: number,
  errorMessage?: string,
) => Promise<any>
export const simulateRequest: SimulateRequest = (
  result,
  timeout = 1000,
  errorMessage = 'Error in request',
) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (result) {
        resolve(result)
      }
      reject(new Error(errorMessage))
    }, timeout)
  })
}

export const capitalizeText = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
}

export const sitesToTotalCapacity = (sites: Site[]) => {
  return sites.reduce(
    (totalCapacity: number, site: Site) => totalCapacity + site.capacity,
    0,
  )
}

type GetTechnology = (sites: Site[]) => string

/**
 * Find the technology in `sites` array and return it.
 * ```ts
 * getTechnology(tender?.sites)
 * @return site[0].technology = "EFW"
 * ```
 */
export const getTechnology: GetTechnology = (sites): string => {
  if (sites.length === 0) return ''

  return sites[0].technology
}

type ParseContractDurationFn = (
  startDate: string,
  endDate: string,
  dateFormat?: string,
) => string

/**
 * Parse two `date strings` into a `string` with both values formatted.
 * ```ts
 * parseContractDuration(tender.startDate, tender.endDate, 'DD/MM/YYYY')
 * @return
 * "01/10/2022 - 01/10/2023"
 * ```
 */
export const parseContractDuration: ParseContractDurationFn = (
  startDate,
  endDate,
  dateFormat = dateFormats.user,
) => {
  const formattedStartDate = dayjs(startDate).format(dateFormat)
  const formattedEndDate = dayjs(endDate).format(dateFormat)
  return `${formattedStartDate} - ${formattedEndDate}`
}

type ParseMaskNumberToStringFn = (
  value?: number | null,
  mask?: { prefix?: string; suffix?: string },
) => string
/**
 * Parse a integer value into a `string` with prefix and/or suffix.
 * Mostly used to parse `number` to *currency format*.
 * **This function assumes that the values it's coming from the *database*,
 * where the integers need to be divided by `100`**.
 * _________________
 * Prefix
 * ```ts
 * parseIntToCurrency(15.25, { prefix: '£ ' })
 * @return
 * "£ 1525"
 * ```
 * _________________
 * Suffix
 * ```ts
 * parseIntToCurrency(15.25, { suffix: ' £/MW' })
 * @return
 * "1525 £/MW"
 * ```
 */
export const parseMaskNumberToString: ParseMaskNumberToStringFn = (
  value,
  config,
) => {
  const resultParsed = value ? (value / 100).toFixed(2) : '0'
  const prefix = config?.prefix ? `${config?.prefix} ` : ''
  const suffix = config?.suffix ? ` ${config?.suffix}` : ''
  return `${prefix}${resultParsed}${suffix}`
}

const mappedBenefits = {
  'FIXED-FIXED': 'fixed',
  'FLEXIBLE-FIXED': 'fixedWithFlexGduos',
  'FLEXIBLE-FLEXIBLE': 'passthrough',
}

type BenefitsToStringFn = (benefits?: {
  [key: string]: 'FIXED' | 'FLEXIBLE' | undefined
}) => string

/**
 * Receives `embeddedBenefits object` and returns a `string` to be translated.
 *
 * ```ts
 * benefitsToString({
 *  gduos: 'FIXED',
 *  bsuos: 'FIXED'
 * })
 * @return
 * "fixed"
 * ```
 * ---------------------
 * ```
 *  * @return
 * // Other return possibilities
 * 'fixedWithFlexGduos', // -> 'FLEXIBLE-FIXED'
 * 'passthrough', // -> 'FLEXIBLE-FLEXIBLE'
 * ```
 */
export const benefitsToString: BenefitsToStringFn = (benefits) => {
  const result = mappedBenefits['FIXED-FIXED']

  if (!benefits) return result
  const { gduos, bsuos } = benefits
  const keyBenefit = `${gduos}-${bsuos}` as keyof typeof mappedBenefits
  if (!gduos || !bsuos || !mappedBenefits[keyBenefit]) return result
  return mappedBenefits[keyBenefit]
}

export const benefitToPPAYAData = {
  FIXED: {
    gduos: { benefitType: 'FIXED' },
    bsuos: { benefitType: 'FIXED' },
    aahedc: { benefitType: 'FIXED' },
    dloss: { benefitType: 'FIXED' },
    tloss: { benefitType: 'FIXED' },
    other: { benefitType: 'FIXED' },
  },
  FIXED_GDUOS: {
    gduos: { benefitType: 'FLEXIBLE' },
    bsuos: { benefitType: 'FIXED' },
    aahedc: { benefitType: 'FIXED' },
    dloss: { benefitType: 'FIXED' },
    tloss: { benefitType: 'FIXED' },
    other: { benefitType: 'FIXED' },
  },
  FLEXIBLE: {
    gduos: { benefitType: 'FLEXIBLE' },
    bsuos: { benefitType: 'FLEXIBLE' },
    aahedc: { benefitType: 'FLEXIBLE' },
    dloss: { benefitType: 'FLEXIBLE' },
    tloss: { benefitType: 'FLEXIBLE' },
    other: { benefitType: 'FLEXIBLE' },
  },
}

type BenefitOptions = keyof typeof benefitToPPAYAData
/**
 * Receives the result from Select `Embedded benefits type` and returns the
 * object linked to it.
 *
 * ```ts
 * benefitToPPAYA('FIXED_GDUOS')
 * @return
 * result = {
    gduos: { benefitType: 'FLEXIBLE' },
    bsuos: { benefitType: 'FIXED' },
    aahedc: { benefitType: 'FIXED' },
    dloss: { benefitType: 'FIXED' },
    tloss: { benefitType: 'FIXED' },
    other: { benefitType: 'FIXED' },
  }
 * ```
 */
export const benefitToPPAYA = (benefit: BenefitOptions): any => {
  return benefitToPPAYAData[benefit]
}

/**
 * Receives the embeddedBenefits from PPAYA and parse it to the select option.
 *
 * ```ts
 * PPAYAToBenefitSelect({
    gduos: { benefitType: 'FLEXIBLE' },
    bsuos: { benefitType: 'FIXED' },
    aahedc: { benefitType: 'FIXED' },
    dloss: { benefitType: 'FIXED' },
    tloss: { benefitType: 'FIXED' },
    other: { benefitType: 'FIXED' },
  })
 * @return
 * result = { value: 'FIXED_GDUOS', label: 'fixedWithFlexGduos' }
 * ```
 */
export const PPAYAToBenefitSelect = (benefits: any) => {
  const result = embeddedBenefitsList[0]
  if (
    !benefits ||
    !benefits?.gduos?.benefitType ||
    !benefits?.bsuos?.benefitType
  )
    return result

  const { gduos, bsuos } = benefits
  if (gduos.benefitType === 'FLEXIBLE') {
    if (bsuos.benefitType === 'FIXED') {
      /** gduos: 'FLEXIBLE', bsuos: 'FIXED' */
      return embeddedBenefitsList[1]
    }
    /* gduos: 'FLEXIBLE', bsuos: 'FLEXIBLE' */
    return embeddedBenefitsList[2]
  }
  /* gduos: 'FIXED', bsuos: 'FIXED' */
  return result
}

type SubsidiesToStringFn = (subsidies?: {
  [key: string]: string | boolean | number | undefined
}) => string

/**
 * Receives `subsidies object` and returns a formatted `string`.
 *
 * ```ts
 * subsidiesToString({
 *  __typename: 'Subsidies', // <-- This will be ignored
 *  fit: true,
 *  roc: "FIXED"
 * })
 * @return
 * "FIT, ROC (fixed)"
 * ```
 */
export const subsidiesToString: SubsidiesToStringFn = (subsidiesData) => {
  if (!subsidiesData) return ''
  const { __typename, ...subsidies } = subsidiesData

  return Object.keys(subsidies)
    .map((key) => {
      const value = subsidies[key]
      if (!value) return false
      const name = key.toUpperCase()
      if (typeof value !== 'string') return name

      const subsidyType =
        value === 'FLEXIBLE' ? '% Passthrough' : value.toLowerCase()

      return `${name} (${subsidyType})`
    })
    .filter(Boolean)
    .join(', ')
}

export const priceTypeOptions = {
  FIXED_AVERAGED: 'fixedAveraged',
  FIXED_SEASONAL: 'fixedSeasonal',
  FIT_EXPORT_HIGH: 'fitExportHigh',
  FIT_EXPORT_LOW: 'fitExportLow',
  FLEXIBLE: 'flexible',
  SPILL: 'spill',
}

/**
 * Receives `subsidies object` and returns a formatted `string`.
 *
 * ```ts
 * priceTypeToString([
 *   { priceType: 'SPILL' },
 *   { priceType: 'FIXED_AVERAGED' }
 * ]
 *  fit: true,
 *  roc: "FIXED"
 * })
 * @return
 * "multipleTypes"
 * ```
 * ---------------------
 * ```
 * @return
 * // Other return possibilities
 * 'fixedAveraged',
 * 'fixedSeasonal',
 * 'fitExportHigh',
 * 'fitExportLow',
 * 'flexible',
 * 'spill'
 * ```
 */
export const priceTypeToString = (periods?: TenderPeriod[]): string => {
  if (!periods || periods.length === 0) return ''

  const priceTypes = periods.reduce(
    (result: string[], period: TenderPeriod): string[] => {
      const isInList = result.includes(period.priceType)
      if (isInList) return result

      return [...result, period.priceType]
    },
    [],
  )

  if (priceTypes.length > 1) return 'multipleTypes'

  return priceTypeOptions[priceTypes[0] as keyof typeof priceTypeOptions]
}

export const calculateSeasonEnd = (date: Dayjs) => {
  const year = date.get('year')
  const winterEnd = dayjs()
    .endOf('day')
    .set('year', year)
    .set('month', 2)
    .set('date', 31)
  const summerEnd = dayjs()
    .endOf('day')
    .set('year', year)
    .set('month', 8)
    .set('date', 30)
  const nextWinterEnd = winterEnd.set('year', year + 1)

  if (date.isBefore(winterEnd)) return winterEnd
  if (date.isAfter(summerEnd)) return nextWinterEnd
  return summerEnd
}

export const isSummerStart = (date: Dayjs): boolean => {
  return date.get('month') + 1 === 4 && date.get('date') === 1
}

export const isSummerEnd = (date: Dayjs): boolean => {
  return date.get('month') + 1 === 9 && date.get('date') === 30
}

export const isWinterStart = (date: Dayjs): boolean => {
  return date.get('month') + 1 === 10 && date.get('date') === 1
}

export const isWinterEnd = (date: Dayjs): boolean => {
  return date.get('month') + 1 === 3 && date.get('date') === 31
}

export function toSeasons(startDate: string, endDate: string) {
  const awsDateFormat = 'YYYY-MM-DD'
  const start = dayjs(startDate)
  const end = dayjs(endDate)

  const seasons = []
  let lastEnd = start.subtract(1, 'day')

  while (lastEnd.isBefore(end)) {
    const seasonStart = lastEnd.add(1, 'day')
    const closestSeasonEnd = calculateSeasonEnd(seasonStart)
    const seasonEnd = closestSeasonEnd.isBefore(end) ? closestSeasonEnd : end
    lastEnd = seasonEnd

    seasons.push({
      startDate: seasonStart.format(awsDateFormat),
      endDate: seasonEnd.format(awsDateFormat),
    })
  }

  return seasons
}

type PeriodObject = {
  startDate: string
  endDate: string
  priceType: keyof typeof priceTypeOptions
  weight: number
}
type PriceTypeToPPAYAFn = (
  period: Omit<PeriodObject, 'weight'>,
) => PeriodObject[]
/**
 * @TODO Create 'Multiple Types' handler
 * @TODO Refactor `priceTypeToPPAYA`, `toSeasons` and `calculateSeasonEnd`
 * with the 'Multiple Types' handler.
 */
export const priceTypeToPPAYA: PriceTypeToPPAYAFn = (period) => {
  const { startDate, endDate, priceType } = period
  if (priceType === 'FIXED_SEASONAL') {
    return toSeasons(startDate, endDate).map((season) => ({
      ...season,
      priceType: 'FIXED_SEASONAL',
      weight: 100,
    }))
  }

  return [
    {
      startDate,
      endDate,
      priceType,
      weight: 100,
    },
  ]
}

export const poundsToPence = (value: string | number): number => {
  if (typeof value === 'string') return +(parseFloat(value) * 100).toFixed(2)
  return +(value * 100).toFixed(2)
}

export const penceToPounds = (value: string | number): number => {
  if (typeof value === 'string') return +(Number(value) / 100).toFixed(2)
  return +(value / 100).toFixed(2)
}

export const ukPhoneNumberRegex =
  // eslint-disable-next-line no-useless-escape
  /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$|\s+$/

export const removeTypename = (obj: Record<string, any> = {}) => {
  const { __typename, ...rest } = obj

  Object.entries(rest).forEach(([key, value]) => {
    if (!value) return

    if (typeof value === 'object') {
      rest[key] = removeTypename(value)
    }
  })

  return rest
}

export const removeFalsyEntries = (obj: Record<string, any>) => {
  const copiedObj = { ...obj }
  Object.keys(copiedObj).forEach((key) => {
    if (
      copiedObj[key] === false ||
      copiedObj[key] === null ||
      copiedObj[key] === undefined
    )
      delete copiedObj[key]
  })

  return copiedObj
}

export const toSubsidiesInput = (obj: Record<string, any> = {}) => {
  const subsidies: Record<string, any> = {}
  const sanitized = removeTypename(removeFalsyEntries(obj))

  Object.entries(sanitized).forEach(([key, value]) => {
    const subType = { subsidyType: value }
    if (key === 'roc') {
      subsidies.rocBuyout = subType
      subsidies.rocRecycle = subType
    } else if (key === 'fit') {
      subsidies.fit = 100
    } else {
      subsidies[key] = subType
    }
  })

  return subsidies
}

export const subsidiesToInputlabel = (subsidy: string) => {
  if (subsidy === 'rocBuyout') return 'ROC (buyout)'
  if (subsidy === 'rocRecycle') return 'ROC (recycle)'

  return subsidy.toUpperCase()
}

export const multiplyValue = (obj: Record<string, any> = {}) => {
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'number') {
      // eslint-disable-next-line no-param-reassign
      obj[key] = value * 100
    }

    if (!value || typeof value !== 'object') return

    if ('bidValue' in value) {
      // eslint-disable-next-line no-param-reassign
      value.bidValue = Math.round(value.bidValue * 100)
    } else if ('chargeValue' in value) {
      // eslint-disable-next-line no-param-reassign
      value.chargeValue = Math.round(value.chargeValue * 100)
    } else {
      multiplyValue(value as Record<string, any>)
    }
  })

  return obj
}

export const parseToFloatFormValues = (formValue: string) => {
  return parseFloat(parseFloat(formValue).toFixed(2))
}

export const getCountryOptionsData = () => {
  const iso = countries.all()
  const result = iso.filter((country) => country.alpha3 === 'IRL')

  return [
    ...result,
    { alpha2: 'GB-ENG', alpha3: 'GB-ENG', country: 'England' },
    { alpha2: 'GB-UKM', alpha3: 'GB-UKM', country: 'United Kingdom' },
    { alpha2: 'GB-WLS', alpha3: 'GB-WLS', country: 'Wales' },
    { alpha2: 'GB-SCT', alpha3: 'GB-SCT', country: 'Scotland' },
    { alpha2: 'GB-NIR', alpha3: 'GB-NIR', country: 'Northern Ireland' },
  ].sort((a, b) => a.country.localeCompare(b.country))
}

export const parseSnippetAddress = (address?: Address) => {
  if (!address) return '-'

  const { addressLine1, addressLine2, postalCode, locality, region, country } =
    address

  const countryOptions = getCountryOptionsData()
  const countryName =
    countryOptions.find((c: any) => c.alpha3 === country)?.country || country

  const addressSnippet = [
    addressLine1,
    addressLine2,
    postalCode,
    locality,
    region,
    countryName,
  ]

  return addressSnippet.filter((item) => item).join(', ')
}

export const validateDecimalNumbers = (value: string | number | undefined) => {
  const inputValue = String(value).trim()

  if (inputValue === '') {
    return true
  }

  const decimalRegex = /^\d+(\.\d+)?$/

  return (
    decimalRegex.test(inputValue) ||
    'Use a dot (.) instead of a comma (,) for decimal numbers.'
  )
}
