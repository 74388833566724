import React, { useState, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import useUser from '../../../hooks/use-user'
import usePPA from '../../../hooks/use-ppa'

import { UserPreferences } from '../../../types'

import { ReactComponent as Spinner } from '../../../assets/spinner/spinner.svg'

import Message from '../../../components/atoms/message'
import Button from '../../../components/atoms/button'
import { feedbackMessage } from '../../../components/atoms/feedback'

import ToggleSwitch from '../../../components/molecules/toggle-switch'
import { Context, DefinedContext } from './types'
import { UserTypes } from '../../../types/user'

export const defaultActionNotifications = {
  email: true,
  sms: true,
  app: true,
}

export interface FormPreferences<UserType extends UserTypes = UserTypes> {
  notifications: Context<UserType>
}

interface Notification {
  action: string
  description: string
  email: boolean
  sms: boolean
  app: boolean
}

export interface MappedPreference {
  context: DefinedContext
  title: string
  notifications: Notification[]
}

interface UserPreferencesProps {
  defaultFormValues: FormPreferences
  mappedPreferences: MappedPreference[]
}

const FormUserPreferences: React.FC<UserPreferencesProps> = ({
  defaultFormValues,
  mappedPreferences,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'settings.notifications',
  })

  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { user } = useUser<'ADMIN' | 'GENERATOR' | 'SUPPLIER' | 'CONSUMER'>()
  const { userId, userType } = user.appMetadata

  const { fetchData, throwFeedbackError } = usePPA()

  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [userPreferences, setUserPreferences] = useState<UserPreferences[]>()

  const { control, handleSubmit, setValue } = useForm<FormPreferences>({
    defaultValues: defaultFormValues,
  })

  /**
   * @DO_NOT_CHANGE
   * @FIX_ME
   * This is set to any because is too much effort to make it work since this file is generic.
   */
  const handleSubmitNotification = async (formData: any) => {
    if (isSubmitting) return
    setIsSubmitting(true)

    const preferences: Omit<FormPreferences, 'notifications'>[] = []

    const payload = {
      userId,
      preferences,
    }

    Object.keys(formData.notifications).forEach((context: string) => {
      const notificationContext = formData.notifications[context]

      Object.keys(notificationContext).forEach((action) => {
        const notificationAction = notificationContext[action]

        if (notificationAction.email !== undefined) {
          payload.preferences.push({
            notificationContextName: context,
            notificationActionName: action,
            notificationChannelName: 'EMAIL',
            active: notificationAction.email,
          })
        }
      })
    })

    try {
      const { error } = await fetchData({
        method: 'POST',
        url: '/core/private/user-preferences',
        body: payload,
      })

      if (error) throw error

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
        },
        'success',
      )
    } catch (err) {
      throwFeedbackError({ err })
    } finally {
      setIsSubmitting(false)
    }
  }

  const fetchUserPreferences = async () => {
    if (isLoading) return
    setIsLoading(true)

    try {
      const { error, response } = await fetchData({
        method: 'GET',
        url: `/core/private/user-preferences/${userId}`,
      })

      if (!response?.data || error) {
        throw error
      }

      setUserPreferences(response.data.userPreferences)
    } catch (err) {
      throwFeedbackError({ err })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (isLoading) return

    fetchUserPreferences()
  }, [])

  useEffect(() => {
    if (isLoading || !userPreferences) return

    userPreferences.forEach((preference: any) => {
      const fieldName: any = `notifications.${preference.notificationContextName}.${preference.notificationActionName}.email`
      setValue(fieldName, preference.active)
    })
  }, [userPreferences])

  const preferences = useMemo(() => {
    if (isLoading || !userPreferences) return undefined

    const updatedPreferences = mappedPreferences.map((preference) => {
      const dataContextList = userPreferences?.filter(
        (itemPreference) =>
          itemPreference.notificationContextName === preference.context,
      )

      const newNotifications = preference.notifications.map(
        (itemNotification) => {
          const result: any = { ...itemNotification }
          const dataByAction = dataContextList?.filter(
            (itemNotificationAction) =>
              itemNotificationAction.notificationActionName ===
              itemNotification.action,
          )

          dataByAction?.forEach((itemNotificationChannel) => {
            if (itemNotificationChannel.notificationChannelName === 'EMAIL')
              result.email = itemNotificationChannel.active
            if (itemNotificationChannel.notificationChannelName === 'SMS')
              result.sms = itemNotificationChannel.active
            if (itemNotificationChannel.notificationChannelName === 'APP')
              result.app = itemNotificationChannel.active
          })
          return result
        },
      )

      return {
        ...preference,
        notifications: newNotifications,
      }
    })

    return updatedPreferences
  }, [userPreferences])

  return (
    <div className="max-w-[700px] ml-3">
      <form
        onSubmit={handleSubmit(handleSubmitNotification)}
        className="flex flex-col gap-y-5 text-ppa/secondary"
      >
        <h1 className="text-xl font-semibold">{t('title')}</h1>
        <Message variant="info" size="text-xs">
          {t('infoMessage')}
        </Message>

        {isLoading && (
          <div className="w-full">
            <Spinner className="mx-auto animate-spin w-5 h-5" />
          </div>
        )}

        {!isLoading && !userPreferences && (
          <div>
            <strong>{t('error.title')}</strong>
            <Button variant="primary" onClick={fetchUserPreferences}>
              {t('error.button')}
            </Button>
          </div>
        )}

        {!isLoading &&
          preferences &&
          preferences.map((preference, idx) => (
            <div className="flex flex-col" key={preference.title}>
              <div className="flex flex-col gap-y-1.5">
                <div className="flex justify-between">
                  <h3 className="font-medium text-sm">
                    {t(`toggleSwitchForm.titles.${preferences[idx].title}`)}
                  </h3>

                  {idx === 0 && (
                    <div className="flex gap-x-9 mb-1.5 font-normal text-xs">
                      <h2>{t('toggleSwitchForm.titles.email')}</h2>
                    </div>
                  )}
                </div>

                {preference.notifications.map((notification) => (
                  <div
                    className="flex justify-between"
                    key={notification.description}
                  >
                    <div>
                      <span className="font-light text-xs">
                        {t(
                          `toggleSwitchForm.fields.${userType.toLocaleLowerCase()}.${
                            notification.description
                          }`,
                        )}
                      </span>
                    </div>
                    <div className="flex items-start gap-x-10">
                      <ToggleSwitch
                        name={`notifications.${preference.context}.${notification.action}.email`}
                        userPreferences={notification.email}
                        control={control}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        <div className="max-w-[205px] mt-2">
          <Button
            type="submit"
            variant="primary"
            fullWidth
            loading={isLoading}
            disabled={isLoading}
          >
            {t('toggleSwitchForm.submitButton')}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default FormUserPreferences
