import React, { useState } from 'react'

import classNames from 'classnames'

import { ChevronDownIcon } from '@heroicons/react/outline'

import { transition, transitionAll } from '../../../styles'

interface CollapseItemData {
  id: string
  [key: string]: any
}

export interface CollapseConfigProps {
  keyName: keyof CollapseItemData
  containerClass?: string
  renderCustomEl?: (
    item: CollapseItemData,
  ) => JSX.Element | string | number | undefined
}

interface CollapseListProps {
  title: string
  titleEmptyList: string
  list?: CollapseItemData[]
  rowKeys: CollapseConfigProps[]
  defaultOpen?: boolean
}

const CollapseList: React.FC<CollapseListProps> = ({
  title,
  titleEmptyList,
  list,
  rowKeys,
  defaultOpen = false,
}) => {
  const [isListOpen, setIsListOpen] = useState(defaultOpen)

  return (
    <div className="flex flex-col max-w-[400px] relative">
      <div
        className={classNames(
          'flex justify-between px-3 py-1.5',
          'text-sm font-medium text-ppa/title',
          'bg-ppa/grayBackground border-b border-ppa/lightGrayBorder',
          'relative z-50',
        )}
      >
        <span>{title}</span>

        <ChevronDownIcon
          data-testid="collapse-list-open-button"
          className={classNames(
            'w-5 h-5 cursor-pointer hover:brightness-75',
            transition,
            isListOpen ? 'rotate-180' : 'rotate-0',
          )}
          onClick={() => setIsListOpen(!isListOpen)}
        />
      </div>

      <div
        data-testid="collapse-list-container"
        className={classNames(
          'text-sm font-light text-ppa/title z-10',
          transitionAll,
          isListOpen
            ? 'relative opacity-1 visible'
            : 'absolute opacity-0 invisible',
        )}
      >
        {list && list.length > 0 ? (
          list.map((item) => (
            <div
              key={item.id}
              className={classNames(
                'flex items-center px-3 py-1.5 w-full',
                'border-b border-ppa/grayBorder',
              )}
            >
              {rowKeys.map((rowKey) => (
                <div key={rowKey.keyName} className={rowKey.containerClass}>
                  {rowKey.renderCustomEl
                    ? rowKey.renderCustomEl(item)
                    : item[rowKey.keyName]}
                </div>
              ))}
            </div>
          ))
        ) : (
          <div
            className={classNames(
              'flex items-center justify-center px-3 py-1.5 w-full font-normal',
              'border-b border-ppa/grayBorder',
            )}
          >
            <span>{titleEmptyList}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default CollapseList
