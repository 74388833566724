import TendersInvited from './invited'
import TendersInvitedDetails from './invited/details'

import TendersActive from './active'
import TendersActiveDetails from './active/details'

import TendersPending from './pending'
import TendersPendingDetails from './pending/details'

import TendersUnderOffer from './under-offer'
import TendersUnderOfferDetails from './under-offer/details'

import TendersExecuted from './executed'
import TendersExecutedDetails from './executed/details'

import TendersClosed from './closed'
import TendersClosedDetails from './closed/details'

export const tendersLinks = [
  { to: '/status/accepted', label: 'tenderStatus.active' },
  { to: '/status/pending', label: 'tenderStatus.invited' },
  { to: '/status/created', label: 'tenderStatus.pending' },
  { to: '/status/underOffer', label: 'tenderStatus.underOffer' },
  { to: '/status/won', label: 'tenderStatus.executed' },
  { to: '/status/lost', label: 'tenderStatus.closed' },
]

export const tendersSubRoutes = {
  accepted: {
    index: <TendersActive />,
    details: TendersActiveDetails,
  },
  pending: {
    index: <TendersInvited />,
    details: TendersInvitedDetails,
  },
  created: {
    index: <TendersPending />,
    details: TendersPendingDetails,
  },
  underOffer: {
    index: <TendersUnderOffer />,
    details: TendersUnderOfferDetails,
  },
  won: {
    index: <TendersExecuted />,
    details: TendersExecutedDetails,
  },
  lost: {
    index: <TendersClosed />,
    details: TendersClosedDetails,
  },
}
