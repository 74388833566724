import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import usePPA from '../../../../../hooks/use-ppa'
import usePPAGetList from '../../../../../hooks/use-ppa/use-ppa-get-list'

import { ConsumerBid } from '../../../../../types'

import { feedbackMessage } from '../../../../../components/atoms/feedback'

import TableConsumerBids, {
  ButtonList as BidsButtonList,
} from '../../../../../components/organisms/consumer-bids-table'

import TenderDetails, { ButtonList } from '../components/details'

import { TenderStatusProps } from '../details'

const ActiveTenderDetails: React.FC<TenderStatusProps> = ({
  tenderDetails,
  refetch,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'consumers.tenders.details',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const {
    isLoading,
    reset,
    throwFeedbackError: bidThrowFeedbackError,
    data,
    error,
  } = usePPAGetList<ConsumerBid>({
    dataKey: 'consumerTenderBids',
    path: `/core/private/consumer-tender-bid/consumer/${tenderDetails.id}`,
  })

  const bidList = useMemo(() => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, isLoading, error])

  const [isWithdrawing, setIsWithdrawing] = useState(false)

  const handleWithdrawn = async () => {
    if (isWithdrawing) return
    setIsWithdrawing(true)

    try {
      const { response, error: err } = await fetchData({
        method: 'PATCH',
        url: '/core/private/consumer-tender/consumer/update-status/',
        body: { consumerTenderId: tenderDetails.id, status: 'WITHDRAWN' },
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('actions.withdraw.success'),
        },
        'success',
      )

      refetch()
    } catch (err: any) {
      throwFeedbackError({
        err,
        context: 'consumerTender',
      })
    } finally {
      setIsWithdrawing(false)
    }
  }

  const [isLoadingBidAction, setIsLoadingBidAction] = useState(false)

  const handleDeclineBid = async (consumerTenderBidId: string) => {
    if (isLoadingBidAction || !bidList) return
    setIsLoadingBidAction(true)

    const findBid = bidList.find((bid) => bid.id === consumerTenderBidId)
    if (!findBid) {
      feedbackMessage(
        {
          title: tUtils('feedbackMessage.error.title'),
          description: t('bids.handleDeclineBid.error.description'),
        },
        'error',
      )
      return
    }

    try {
      const { response, error: err } = await fetchData({
        method: 'PATCH',
        url: '/core/private/consumer-tender-bid/consumer/reject',
        body: { consumerTenderBidId },
      })

      if (err || !response) throw err

      reset()
      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('bids.handleDeclineBid.success.description'),
        },
        'success',
      )
    } catch (err: any) {
      throwFeedbackError({
        err,
        context: 'consumerTenderBid',
        AUTHORIZATION_ERROR: ({ message }) => {
          if (
            message?.includes(
              'You do not have permission to change the Bid to this status',
            )
          ) {
            return {
              title: tUtils(
                'throwFeedbackError.errorCodes.consumerTenderBid.ERR_NOT_AUTHORIZED.title',
              ),
              description: tUtils(
                'throwFeedbackError.errorCodes.consumerTenderBid.ERR_NOT_AUTHORIZED.reason.STATUS_PERMISSION',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    } finally {
      setIsLoadingBidAction(false)
    }
  }

  const handleAcceptBid = async (consumerTenderBidId: string) => {
    if (isLoadingBidAction || !bidList) return
    setIsLoadingBidAction(true)

    const findBid = bidList.find((bid) => bid.id === consumerTenderBidId)
    if (!findBid) {
      feedbackMessage(
        {
          title: tUtils('feedbackMessage.error.title'),
          description: t('bids.handleAcceptBid.error.description'),
        },
        'error',
      )
      return
    }

    try {
      const { response, error: err } = await fetchData({
        method: 'PATCH',
        url: '/core/private/consumer-tender-bid/consumer/accept',
        body: { consumerTenderBidId },
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('bids.handleAcceptBid.success.description'),
        },
        'success',
      )

      refetch()
    } catch (err: any) {
      throwFeedbackError({
        err,
        context: 'ConsumerTenderBid',
        AUTHORIZATION_ERROR: ({ message }) => {
          if (message?.includes('You cannot accept a Bid that has expired')) {
            return {
              title: tUtils(
                'throwFeedbackError.errorCodes.ConsumerTenderBid.ERR_NOT_AUTHORIZED.title',
              ),
              description: tUtils(
                'throwFeedbackError.errorCodes.ConsumerTenderBid.ERR_NOT_AUTHORIZED.reason.CANNOT_ACCEPT_EXPIRED_BID',
              ),
              type: 'error',
            }
          }
          if (
            message?.includes(
              'You do not have permission to change the Bid to this status',
            )
          ) {
            return {
              title: tUtils(
                'throwFeedbackError.errorCodes.consumerTenderBid.ERR_NOT_AUTHORIZED.title',
              ),
              description: tUtils(
                'throwFeedbackError.errorCodes.consumerTenderBid.ERR_NOT_AUTHORIZED.reason.STATUS_PERMISSION',
              ),
              type: 'error',
            }
          }
          return undefined
        },
      })
    } finally {
      setIsLoadingBidAction(false)
    }
  }

  const bidButtons = useMemo((): BidsButtonList[] | undefined => {
    if (!bidList || isLoading || error) return undefined

    if (!tenderDetails) return undefined

    if (tenderDetails.status === 'ACTIVE') {
      return [
        {
          props: { variant: 'primary', loading: isLoadingBidAction },
          onClick: handleAcceptBid,
          text: t('bids.accept'),
        },
        {
          props: { variant: 'secondary', loading: isLoadingBidAction },
          onClick: handleDeclineBid,
          text: t('bids.decline'),
        },
      ]
    }

    return undefined
  }, [bidList, tenderDetails, isLoadingBidAction])

  const buttons = useMemo((): ButtonList[] | undefined => {
    return [
      {
        text: t('actions.withdraw.button'),
        onClick: handleWithdrawn,
        props: { variant: 'secondary', loading: isWithdrawing },
      },
    ]
  }, [tenderDetails])

  useEffect(() => {
    if (error) {
      bidThrowFeedbackError({ err: error })
    }
  }, [bidList])

  return (
    <section className="flex flex-col gap-y-8">
      <TenderDetails tender={tenderDetails} buttons={buttons} />
      <TableConsumerBids
        bids={bidList}
        tenderStatus={tenderDetails.status}
        refetch={refetch}
        buttons={bidButtons}
        enableSelectingBids
      />
    </section>
  )
}

export default ActiveTenderDetails
