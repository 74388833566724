import SleevedTendersActive from './active'
import SleevedTendersActiveDetails from './active/details'

import SleevedTendersInvited from './invited'
import InvitedSleevedTenderDetails from './invited/details'

import SleevedTendersPending from './pending'
import SleevedTendersPendingDetails from './pending/details'

import SleevedTendersExecuted from './executed'
import SleevedTendersExecutedDetails from './executed/details'

import SleevedTendersUnderOffer from './under-offer'
import SleevedTendersUnderOfferDetails from './under-offer/details'

import SleevedTendersClosed from './closed'
import SleevedTendersClosedDetails from './closed/details'

export const sleevedTendersLinks = [
  { to: '/status/accepted', label: 'tenderStatus.active' },
  { to: '/status/pending', label: 'tenderStatus.invited' },
  { to: '/status/created', label: 'tenderStatus.pending' },
  { to: '/status/underOffer', label: 'tenderStatus.underOffer' },
  { to: '/status/won', label: 'tenderStatus.executed' },
  { to: '/status/lost', label: 'tenderStatus.closed' },
]

export const sleevedTendersSubRoutes = {
  accepted: {
    index: <SleevedTendersActive />,
    details: SleevedTendersActiveDetails,
  },
  pending: {
    index: <SleevedTendersInvited />,
    details: InvitedSleevedTenderDetails,
  },
  created: {
    index: <SleevedTendersPending />,
    details: SleevedTendersPendingDetails,
  },
  underOffer: {
    index: <SleevedTendersUnderOffer />,
    details: SleevedTendersUnderOfferDetails,
  },
  won: {
    index: <SleevedTendersExecuted />,
    details: SleevedTendersExecutedDetails,
  },
  lost: {
    index: <SleevedTendersClosed />,
    details: SleevedTendersClosedDetails,
  },
}
