import React, { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import usePPA from '../../../../../hooks/use-ppa'

import { TenderStatusProps } from '../details'
import TenderDetails, { ButtonList } from '../components/details'

import { feedbackMessage } from '../../../../../components/atoms/feedback'

const TendersPendingDetails: React.FC<TenderStatusProps> = ({
  tenderDetails,
  refetch,
}) => {
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.tenders.details',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const [isActivating, setIsActivating] = useState(false)
  const [isRejecting, setIsRejecting] = useState(false)

  const handleActivate = async () => {
    if (isActivating || !tenderDetails) return
    setIsActivating(true)

    try {
      const { response, error: err } = await fetchData({
        method: 'PATCH',
        url: '/core/private/tender/admin/approve',
        body: { tenderId: tenderDetails.id },
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('actions.activate.success'),
        },
        'success',
      )

      refetch()
    } catch (err: any) {
      throwFeedbackError({
        err,
        context: 'tender',
      })
    } finally {
      setIsActivating(false)
    }
  }

  const handleReject = async () => {
    if (isRejecting || !tenderDetails) return
    setIsRejecting(true)

    try {
      const { response, error: err } = await fetchData({
        method: 'PATCH',
        url: '/core/private/tender/admin/reject',
        body: { tenderId: tenderDetails.id },
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('actions.reject.success'),
        },
        'success',
      )

      refetch()
    } catch (err: any) {
      throwFeedbackError({
        err,
        context: 'tender',
      })
    } finally {
      setIsRejecting(false)
    }
  }

  const buttons = useMemo((): ButtonList[] | undefined => {
    return [
      {
        text: t('buttons.activate'),
        onClick: handleActivate,
        props: { variant: 'primary', loading: isActivating },
      },
      {
        text: t('buttons.reject'),
        onClick: handleReject,
        props: { variant: 'secondary', loading: isRejecting },
      },
    ]
  }, [tenderDetails, isActivating, handleActivate, isRejecting, handleReject])

  return (
    <section className="flex flex-col gap-y-8">
      <TenderDetails tender={tenderDetails} buttons={buttons} />
    </section>
  )
}

export default TendersPendingDetails
