/* eslint-disable import/prefer-default-export */

import { Tender } from '../../../../types'
import { consumerTendersSubRoutes } from '../tenders/routes'

/**
 * @TODO  add consumer tender type and use it here
 */
export const mappedConsumerTenderStatus: Record<
  Tender['status'],
  keyof typeof consumerTendersSubRoutes | 'draft' | 'archived'
> = {
  ACTIVE: 'active',
  UNDER_OFFER: 'underOffer',
  EXECUTED: 'executed',
  DRAFT: 'draft',
  WITHDRAWN: 'withdrawn',
  ARCHIVED: 'archived',
  PENDING: 'pending',
}
