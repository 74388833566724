import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ReactComponent as TenderDetailsPageIcon } from '../../../../assets/SeeDetails.svg'

import { parseContractDuration } from '../../../../../../utils'

import DefList from '../../../../../../components/atoms/def-list'

import { SupplierTenderInvitation } from '../../../../../../types'
import { mappedMatchPercentage } from '../../../../../../utils/data'

interface TenderDetailsListSleevedProps {
  data: SupplierTenderInvitation['supplierTender']
}

const SleevedConsumerTenderDetailsDefList: React.FC<
  TenderDetailsListSleevedProps
> = ({ data }) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.sleevedTenders.details.defList',
  })

  const navigate = useNavigate()

  const rowKeys = useMemo(() => {
    const details = [
      {
        keyName: 'contractDuration',
        title: t('titles.contractDuration'),
        renderCustomEl: (
          item: SupplierTenderInvitation['supplierTender']['consumerTender'],
        ) => {
          return parseContractDuration(item.startDate, item.endDate)
        },
      },
      ...(data?.contractType?.name === 'SLEEVED'
        ? [
            {
              keyName: 'targetMatch',
              title: t('titles.targetMatch'),
              renderCustomEl: (
                item: SupplierTenderInvitation['supplierTender']['consumerTender'],
              ) => {
                if (!item.targetMatch) return undefined
                return mappedMatchPercentage[item.targetMatch]
              },
            },
          ]
        : []),
      {
        keyName: 'greenTariff',
        title: t('titles.greenTariff'),
        renderCustomEl: (
          item: SupplierTenderInvitation['supplierTender']['consumerTender'],
        ) => {
          if (!item.greenTariff) return undefined
          return item.greenTariff ? 'Yes' : 'No'
        },
      },
    ]
    return details
  }, [data])

  return (
    <div>
      <div className="flex items-center mt-5 mb-3">
        <h2 className="font-semibold text-lg text-ppa/title">
          {t('importTenderTitle')}
        </h2>
        <TenderDetailsPageIcon
          className="ml-3"
          onClick={() => navigate(`/consumerTenders/${data.id}`)}
        />
      </div>
      <DefList rowKeys={rowKeys} data={data} />
    </div>
  )
}

export default SleevedConsumerTenderDetailsDefList
