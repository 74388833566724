/* eslint-disable dot-notation */
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import usePPA from '../../../../../../hooks/use-ppa'

import { feedbackMessage } from '../../../../../../components/atoms/feedback'

import SlidingDrawer from '../../../../../../components/molecules/sliding-drawer'

import { dateFormats } from '../../../../../../utils/data'

import PlaceBidForm, {
  FormData,
  FormCharges,
  targetMatchOptions,
} from './form-place-bid'

import {
  TenderMeterWithInvitation,
  ConsumerStandingChargePowerPriceType,
  ConsumerCapacityChargePowerPriceType,
} from '../../../../../../types'

dayjs.extend(utc)
dayjs.extend(timezone)

interface MatchedRate {
  id: string
  value: string
  consumerTenderBidId: string
  matchType: 'MATCHED' | 'UNMATCHED'
  rateType: 'DAY_RATE' | 'NIGHT_RATE'
  createdAt: string
}
interface FormProps {
  tenderInvitation: TenderMeterWithInvitation
  isDrawerOpen: boolean
  onSuccess: () => void
  closeDrawer: () => void
}

const CreateOrUpdateBid: React.FC<FormProps> = ({
  tenderInvitation,
  onSuccess,
  isDrawerOpen,
  closeDrawer,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.consumerTenders.details.bids.placeBid',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmitPlaceBidForm = async (data: FormData) => {
    if (isLoading) return
    setIsLoading(true)

    try {
      const charges = [
        {
          name: 'STANDING_CHARGE',
          type: data.charges.standing.type,
          value: parseFloat(data.charges.standing.value),
        },
        {
          name: 'CAPACITY_CHARGE',
          type: data.charges.capacity.type,
          value: parseFloat(data.charges.capacity.value),
        },
      ]

      let rates

      if (data.contractType?.value === 'SLEEVED') {
        rates = [
          {
            rateType: 'DAY_RATE',
            matchType: 'MATCHED',
            value: Number(data.dayRate.matchedPowerPrice || data.dayRate.value),
          },
          {
            rateType: 'DAY_RATE',
            matchType: 'UNMATCHED',
            value: Number(
              data.dayRate.unmatchedPowerPrice || data.dayRate.value,
            ),
          },
          {
            rateType: 'NIGHT_RATE',
            matchType: 'MATCHED',
            value: Number(
              data.nightRate.matchedPowerPrice || data.nightRate.value,
            ),
          },
          {
            rateType: 'NIGHT_RATE',
            matchType: 'UNMATCHED',
            value: Number(
              data.nightRate.unmatchedPowerPrice || data.nightRate.value,
            ),
          },
        ]
      } else {
        rates = {
          dayRate: Number(data.dayRate.value),
          nightRate: Number(data.nightRate.value),
        }
      }

      const { response, error: err } = await fetchData({
        method: 'PUT',
        url: '/core/private/consumer-tender-bid/supplier',
        body: {
          consumerTenderId: tenderInvitation.consumerTender.id,
          expiresAt: data.expiresAt,
          greenContract: data.greenContract,
          contractType: data.contractType?.value,
          forecastMatch:
            data.contractType?.value === 'SLEEVED'
              ? data.forecastMatch?.value
              : null,
          rates,
          charges,
        },
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('handlePlaceBid.success.description'),
        },
        'success',
      )
      onSuccess()
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'consumerTenderBid',
        NOT_FOUND_ERROR: ({ message }) => {
          if (message.includes('No Bid found')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.bid.NOT_FOUND_ERROR.update.notFound',
              ),
              type: 'error',
            }
          }

          return undefined
        },
        PAYLOAD_ERROR: ({ message }) => {
          if (message.includes('Bid.checkForInactiveCharges()')) {
            const [_, charges] = message.split('Some Charges are inactive')
            return {
              title: tUtils(
                'throwFeedbackError.errorCodes.bid.PAYLOAD_ERROR.title',
              ),
              description: `${tUtils(
                'throwFeedbackError.errorCodes.bid.PAYLOAD_ERROR.reason.INACTIVE_CHARGES',
              )}\n${charges}.`,
              type: 'error',
            }
          }

          return undefined
        },
      })
    } finally {
      setIsLoading(false)
    }
  }

  const defaultFormValues = useMemo((): FormData => {
    if (!tenderInvitation?.consumerTenderBid) {
      return {
        dayRate: {
          value: '',
          matchedPowerPrice: undefined,
          unmatchedPowerPrice: undefined,
        },
        nightRate: {
          value: '',
          matchedPowerPrice: undefined,
          unmatchedPowerPrice: undefined,
        },
        forecastMatch: undefined,
        charges: {
          capacity: {
            type: 'pence_kva_day',
            value: '',
          },
          standing: {
            type: 'pence_day',
            value: '',
          },
        },
        greenContract: false,
        contractType: undefined,
        expiresAt: dayjs().hour(17).minute(30).format(dateFormats.dateInput),
      }
    }

    const {
      dayRate,
      nightRate,
      greenContract,
      contractType,
      forecastMatch,
      expiresAt,
      consumerBidCharges,
      matchedRates,
    } = tenderInvitation.consumerTenderBid

    const charges: FormCharges = {
      capacity: {
        type: 'pence_kva_day',
        value: '0.0',
      },
      standing: {
        type: 'pence_day',
        value: '0.0',
      },
    }

    consumerBidCharges.forEach((charge) => {
      if (charge.name === 'STANDING_CHARGE') {
        charges.standing = {
          type: charge.type as ConsumerStandingChargePowerPriceType,
          value: charge.value,
        }
      } else if (charge.name === 'CAPACITY_CHARGE') {
        charges.capacity = {
          type: charge.type as ConsumerCapacityChargePowerPriceType,
          value: charge.value,
        }
      }
    })

    const matchedRatesByType: Record<
      'DAY_RATE' | 'NIGHT_RATE',
      { matchedPowerPrice?: string; unmatchedPowerPrice?: string }
    > = {
      DAY_RATE: {
        matchedPowerPrice: undefined,
        unmatchedPowerPrice: undefined,
      },
      NIGHT_RATE: {
        matchedPowerPrice: undefined,
        unmatchedPowerPrice: undefined,
      },
    }

    matchedRates.forEach((rate: MatchedRate) => {
      const { rateType } = rate
      if (rate.matchType === 'MATCHED') {
        matchedRatesByType[rateType].matchedPowerPrice = rate.value
      } else if (rate.matchType === 'UNMATCHED') {
        matchedRatesByType[rateType].unmatchedPowerPrice = rate.value
      }
    })

    const dayRateValues = matchedRatesByType['DAY_RATE']
    const nightRateValues = matchedRatesByType['NIGHT_RATE']

    const forecastMatchOption =
      tenderInvitation?.consumerTenderBid.contractType === 'SLEEVED'
        ? targetMatchOptions.find(
            (option) => option.value === forecastMatch,
          ) || { value: forecastMatch, label: '' }
        : null

    return {
      dayRate: {
        value: dayRate,
        matchedPowerPrice: dayRateValues.matchedPowerPrice,
        unmatchedPowerPrice: dayRateValues.unmatchedPowerPrice,
      },
      nightRate: {
        value: nightRate,
        matchedPowerPrice: nightRateValues.matchedPowerPrice,
        unmatchedPowerPrice: nightRateValues.unmatchedPowerPrice,
      },
      charges,
      greenContract,
      contractType: {
        value: contractType,
        label: tUtils(`priceTypeConsumer.${contractType.toLowerCase()}`),
      },
      forecastMatch: forecastMatchOption as any,
      /**
       * @TODO fix format when BE starts saving the dates as UTC
       */
      expiresAt: dayjs
        .utc(expiresAt)
        .tz('Europe/London')
        .format('YYYY-MM-DDTHH:mm:ss'),
    }
  }, [tenderInvitation, isDrawerOpen])

  return (
    <SlidingDrawer isOpen={isDrawerOpen} handleClose={closeDrawer}>
      <div className="flex flex-col gap-y-5 sm:w-[510px]">
        {isDrawerOpen && (
          <PlaceBidForm
            isLoading={isLoading}
            defaultValues={defaultFormValues}
            onSubmit={handleSubmitPlaceBidForm}
            bid={tenderInvitation?.consumerTenderBid}
            brokerFee={tenderInvitation.consumerTender.consumerMeter?.brokerFee}
          />
        )}
      </div>
    </SlidingDrawer>
  )
}

export default CreateOrUpdateBid
