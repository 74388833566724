import { useMemo } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'

import { validateDecimalNumbers } from '../../../../../../utils'

import { TenderSiteWithInvitation } from '../../../../../../types'
import { Quote as QuoteData } from '../../../../../../types/quote'

import RequiredField from '../../../../../../components/atoms/required-form-field'
import InputGroup from '../../../../../../components/molecules/input-group'
import Message from '../../../../../../components/atoms/message'
import Button from '../../../../../../components/atoms/button'
import Input from '../../../../../../components/atoms/input'

import TargetPrice, { SliderParams } from '../target-price'
import Quote from '../quote'

export type FormValues = {
  targetPrice: string
  minimumPrice?: string
}

interface TargetPriceFormProps {
  refetchQuote: () => void
  onSubmit: (data: any) => void
  onSkip?: () => void
  onCancel?: () => void
  isLoading?: boolean
  quotePayload?: any
  quoteError?: string
  quote?: QuoteData
  tender?: TenderSiteWithInvitation
  targetPrice?: string
  minimumPrice?: string
}

const TargetPriceForm: React.FC<TargetPriceFormProps> = ({
  refetchQuote,
  onSubmit,
  onSkip,
  onCancel,
  isLoading,
  quote,
  quotePayload,
  quoteError,
  tender,
  targetPrice = '',
  minimumPrice = '',
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.tenders',
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      targetPrice,
      minimumPrice,
    },
  })

  const daysUntilTenderStarts = useMemo((): number | undefined => {
    if (!tender) return undefined

    return dayjs(tender.startDate).subtract(7, 'days').diff(dayjs(), 'days')
  }, [tender])

  const isMinimumPriceRequired = useMemo(() => {
    if (!tender?.startDate) return undefined
    return dayjs(tender.startDate).isBefore(dayjs().add(3, 'months'))
  }, [tender?.startDate])

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-1 flex-col gap-y-5"
    >
      <Quote
        tender={tender}
        quoteData={quote}
        quoteError={quoteError}
        isLoading={isLoading}
        refetchQuote={refetchQuote}
      />
      <Controller
        name="targetPrice"
        control={control}
        rules={{
          required: t('drawerTargetPrice.targetPrice.required'),
          validate: {
            isValidDecimal: validateDecimalNumbers,
          },
        }}
        render={({ field: { name, onChange, value } }) => (
          <TargetPrice
            name={name}
            onChange={onChange}
            quotePayload={quotePayload}
            daysUntilTenderStarts={daysUntilTenderStarts}
            value={value}
            error={errors.targetPrice?.message}
          />
        )}
      />
      <Controller
        name="minimumPrice"
        control={control}
        rules={{
          required: {
            value: isMinimumPriceRequired || false,
            message: t('drawerTargetPrice.minimumPrice.required'),
          },
          validate: {
            isValidDecimal: validateDecimalNumbers,
          },
        }}
        render={({ field: { ...props } }) => (
          <InputGroup
            label={t('drawerTargetPrice.minimumPrice.title')}
            error={errors.minimumPrice?.message}
            required={isMinimumPriceRequired}
          >
            <Message variant="info" size="text-xs self-center">
              {t('drawerTargetPrice.minimumPrice.infoMessage')}
            </Message>
            <div className="max-w-[275px] mt-2">
              <Input
                type="text"
                placeholder="0.0"
                suffix="£/MWh"
                error={errors.minimumPrice?.message}
                {...props}
              />
            </div>
          </InputGroup>
        )}
      />
      <div>
        <RequiredField />
      </div>
      <div className="flex items-center justify-end w-full gap-x-5">
        {onCancel && (
          <div className="max-w-[100px] w-full">
            <Button
              type="button"
              onClick={onCancel}
              variant="secondary"
              fullWidth
              disabled={isLoading}
            >
              {t('drawerTargetPrice.cancel')}
            </Button>
          </div>
        )}
        {onSkip && (
          <div className="max-w-[100px] w-full">
            <Button
              type="button"
              onClick={onSkip}
              variant="secondary"
              fullWidth
              disabled={isLoading}
            >
              {t('drawerTargetPrice.skip')}
            </Button>
          </div>
        )}
        <div className="max-w-[150px] w-full">
          <Button type="submit" variant="primary" fullWidth loading={isLoading}>
            {t('drawerTargetPrice.submit')}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default TargetPriceForm
