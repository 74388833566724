/* eslint-disable import/prefer-default-export */

import { TenderInvitation } from '../../../../types'
import { tendersSubRoutes } from '../export-tenders/routes'

export type AvailableRouteStatus = Exclude<
  TenderInvitation['status'],
  'ARCHIVED' | 'REJECTED'
>

export const mappedTenderStatus: Record<
  AvailableRouteStatus | 'ACTIVE',
  keyof typeof tendersSubRoutes | 'archived'
> = {
  CREATED: 'created',
  ACCEPTED: 'accepted',
  ACTIVE: 'accepted',
  PENDING: 'pending',
  UNDER_OFFER: 'underOffer',
  WON: 'won',
  LOST: 'lost',
}
