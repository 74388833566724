import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import usePPA from '../../../../../hooks/use-ppa'

import TenderDetails, { ButtonList } from '../components/details'

import { feedbackMessage } from '../../../../../components/atoms/feedback'

import { SleevedTenderDetailsProps } from '../details'

const PendingSleevedTenderDetails: React.FC<SleevedTenderDetailsProps> = ({
  tender,
  refetch,
}) => {
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.sleevedTenders.details',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const [isActivating, setIsActivating] = useState(false)
  const [isRejecting, setIsRejecting] = useState(false)

  const handleActivate = async () => {
    if (isActivating || !tender) return
    setIsActivating(true)

    try {
      const { response, error: err } = await fetchData({
        method: 'PATCH',
        url: '/core/private/supplier-tender/admin/approve',
        body: { supplierTenderId: tender.id },
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('actions.activate.success'),
        },
        'success',
      )

      refetch()
    } catch (err: any) {
      throwFeedbackError({
        err,
        context: 'supplierTender',
      })
    } finally {
      setIsActivating(false)
    }
  }

  const handleReject = async () => {
    if (isRejecting || !tender) return
    setIsRejecting(true)

    try {
      const { response, error: err } = await fetchData({
        method: 'PATCH',
        url: '/core/private/supplier-tender/admin/reject',
        body: { supplierTenderId: tender.id },
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('actions.reject.success'),
        },
        'success',
      )

      refetch()
    } catch (err: any) {
      throwFeedbackError({
        err,
        context: 'supplierTender',
      })
    } finally {
      setIsRejecting(false)
    }
  }

  const buttons = useMemo((): ButtonList[] | undefined => {
    return [
      {
        text: t('buttons.activate'),
        onClick: handleActivate,
        props: { variant: 'primary', loading: isActivating },
      },
      {
        text: t('buttons.reject'),
        onClick: handleReject,
        props: { variant: 'secondary', loading: isRejecting },
      },
    ]
  }, [tender, isActivating, handleActivate, isRejecting, handleReject])

  return (
    <section className="flex flex-col gap-y-8">
      <TenderDetails tender={tender} buttons={buttons} />
    </section>
  )
}

export default PendingSleevedTenderDetails
