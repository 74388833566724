import React from 'react'

import TenderDetails from '../components/details'

import { TenderStatusProps } from '../details'

const PendingTenderDetails: React.FC<TenderStatusProps> = ({
  tenderDetails,
}) => {
  return (
    <section className="flex flex-col gap-y-8">
      <TenderDetails tender={tenderDetails} />
    </section>
  )
}

export default PendingTenderDetails
