import ConsumerTendersInvited from './invited'
import ConsumerTendersInvitedDetails from './invited/details'

import ConsumerTendersActive from './active'
import ConsumerTendersActiveDetails from './active/details'

import ConsumerTendersPending from './pending'
import ConsumerTendersPendingDetails from './pending/details'

import ConsumerTendersUnderOffer from './under-offer'
import ConsumerTenderUnderOfferDetails from './under-offer/details'

import ConsumerTendersExecuted from './executed'
import ConsumerTendersExecutedDetails from './executed/details'

export const consumerTendersLinks = [
  { to: '/status/accepted', label: 'tenderStatus.active' },
  { to: '/status/pending', label: 'tenderStatus.invited' },
  { to: '/status/created', label: 'tenderStatus.pending' },
  { to: '/status/underOffer', label: 'tenderStatus.underOffer' },
  { to: '/status/won', label: 'tenderStatus.executed' },
]

export const consumerTendersSubRoutes = {
  accepted: {
    index: <ConsumerTendersActive />,
    details: ConsumerTendersActiveDetails,
  },
  pending: {
    index: <ConsumerTendersInvited />,
    details: ConsumerTendersInvitedDetails,
  },
  created: {
    index: <ConsumerTendersPending />,
    details: ConsumerTendersPendingDetails,
  },
  underOffer: {
    index: <ConsumerTendersUnderOffer />,
    details: ConsumerTenderUnderOfferDetails,
  },
  won: {
    index: <ConsumerTendersExecuted />,
    details: ConsumerTendersExecutedDetails,
  },
}
