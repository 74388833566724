/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef } from 'react'
import classNames from 'classnames'
import { DotsHorizontalIcon } from '@heroicons/react/solid'
import useClickOutside from '../../../hooks/use-click-outside'
import { transition } from '../../../styles'

const dropdownDirections = {
  'top-left': {
    position: 'top-0 right-0 -translate-y-full',
    visible: {
      on: '-translate-y-full',
    },
  },
  'top-right': {
    position: 'top-0 left-0 -translate-y-full',
    visible: {
      on: '-translate-y-full',
    },
  },
  'bottom-left': {
    position: 'bottom-0 right-0 translate-y-full',
    visible: {
      on: '-translate-y-full',
    },
  },
  'bottom-right': {
    position: 'bottom-0 left-0 translate-y-full',
    visible: {
      on: '-translate-y-full',
    },
  },
}

export type DropdownDirection = keyof typeof dropdownDirections
export interface DropdownProps {
  content: React.ReactNode
  orientation?: DropdownDirection
  customButton?: JSX.Element
}

const Dropdown: React.FC<DropdownProps> = ({
  content,
  orientation = 'bottom-left',
  customButton,
}) => {
  const dropdownRef = useRef(null)

  const [visible, setVisible] = useState(false)

  useClickOutside({
    ref: dropdownRef,
    onClickOutside: () => setVisible(false),
  })

  const toggleDropdown = () => {
    setVisible(!visible)
  }

  return (
    <div className="relative" ref={dropdownRef}>
      <div onClick={toggleDropdown} className="relative z-0">
        {customButton || (
          <button
            className={classNames(
              'bg-transparent border border-solid rounded-full p-1',
              !visible
                ? 'border-transparent text-ppa/title'
                : 'border-ppa/primary text-ppa/primary',
            )}
            type="button"
          >
            <DotsHorizontalIcon className="w-5 h-5" />
          </button>
        )}
      </div>
      <div
        className={classNames(
          transition,
          'absolute flex min-w-max transform z-50 shadow-md rounded-md',
          dropdownDirections[orientation].position,
          visible
            ? `${dropdownDirections[orientation].visible.on} h-auto`
            : `translate-y-0 h-0 overflow-hidden`,
        )}
      >
        <div className="flex flex-col items-center bg-white text-ppa/title py-2 px-4">
          {content}
        </div>
      </div>
    </div>
  )
}

export default Dropdown
