import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  ChevronRightIcon,
  ChevronLeftIcon,
  MenuAlt4Icon,
  BanIcon,
  CheckIcon,
  XCircleIcon,
  BadgeCheckIcon,
  TrashIcon,
} from '@heroicons/react/outline'
import classNames from 'classnames'

import usePPAGetDetails from '../../../../hooks/use-ppa/use-ppa-get-details'
import usePPA from '../../../../hooks/use-ppa'
import useUser from '../../../../hooks/use-user'

import { isFeatureEnabled } from '../../../../utils/features'

import { container, breakPointsContainer } from '../../../../styles'
import { ReactComponent as Spinner } from '../../../../assets/spinner/spinner.svg'
import { ReactComponent as UserIcon } from './assets/userIcon.svg'
import { ReactComponent as MemberIcon } from './assets/memberIcon.svg'

import UserPill from '../../components/user-pill'
import Dropdown from '../../../../components/atoms/dropdown'
import DefList from '../../../../components/atoms/def-list'
import Button from '../../../../components/atoms/button'

import { feedbackMessage } from '../../../../components/atoms/feedback'
import Table, { TableConfigProps } from '../../../../components/atoms/table'

import BrokerFeeDrawer from './components/broker-fee-drawer'
import ChangeMemberTypeDrawer from '../components/change-member-type'

import { capitalizeText } from '../../../../utils'
import { BrokerFeeGroup, Member, Generator } from '../../../../types'

export type GeneratorDetails = Generator & {
  brokerFeeRuleGroups?: Array<BrokerFeeGroup>
  members: Member[]
}

const rowKeys: TableConfigProps[] = [
  {
    keyName: 'name',
    renderCustomEl: (member: Member) => {
      if (member.firstName || member.lastName || member.email) {
        return (
          <div className="flex">
            <UserIcon />
            <div className="ml-6 font-normal">
              {member.firstName} {member.lastName}
              <div className="font-light">{member?.email}</div>
            </div>
          </div>
        )
      }
      return undefined
    },
  },
  {
    keyName: 'phoneNumber',
  },
]

const Details: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.generators.details',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { actAs, user } = useUser<'ADMIN'>()
  const { fetchData, throwFeedbackError } = usePPA()
  const navigate = useNavigate()

  const [isBrokerDrawerOpen, setIsBrokerDrawerOpen] = useState<boolean>(false)
  const [isMemberTypeDrawerOpen, setIsMemberTypeDrawerOpen] =
    useState<boolean>(false)
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null)

  const { generatorId } = useParams<{ generatorId?: string }>()

  const {
    isLoading,
    refetch,
    throwFeedbackError: detailsThrowFeedbackError,
    data,
    error,
  } = usePPAGetDetails<GeneratorDetails>({
    dataKey: 'generator',
    path: `/core/private/generator/details/${generatorId}`,
  })

  const generatorDetails = useMemo((): GeneratorDetails | undefined => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, isLoading, error])

  /* const {
    data: companyData,
    isLoading: companyLoading,
    error: companyError,
  } = usePPAGetList<Company>({
    dataKey: 'companies',
    path: '/core/private/generator/company/list',
    pagination: {
      offset: 0,
      limit: 100,
    },
  })

  const companies = useMemo((): Company[] | undefined => {
    if (!companyData || companyLoading || error) return []

    return companyData
  }, [companyData, companyLoading, companyError]) */

  const rowKeysCompany = useMemo(() => {
    return [
      {
        keyName: 'company.name',
        title: t('defList.company.name'),
        renderCustomEl: (generator: GeneratorDetails) => {
          return generator.parentCompany.name
        },
      },
      {
        keyName: 'company.number',
        title: t('defList.company.number'),
        renderCustomEl: (generator: GeneratorDetails) => {
          return generator.parentCompany.number
        },
      },
      {
        keyName: 'company.address',
        title: t('defList.company.address'),
        renderCustomEl: (generator: GeneratorDetails) => {
          const {
            addressLine1,
            addressLine2,
            country,
            locality,
            postalCode,
            region,
          } = generator.parentCompany.address
          return [
            addressLine1,
            addressLine2,
            country,
            locality,
            postalCode,
            region,
          ]
            .filter((item) => item)
            .join(', ')
        },
      },
      {
        keyName: 'company.billingAddress',
        title: t('defList.company.billingAddress'),
        renderCustomEl: (generator: GeneratorDetails) => {
          if (!generator.parentCompany?.billingAddress) return undefined
          const {
            addressLine1,
            addressLine2,
            country,
            locality,
            postalCode,
            region,
          } = generator.parentCompany.billingAddress
          return [
            addressLine1,
            addressLine2,
            country,
            locality,
            postalCode,
            region,
          ]
            .filter((item) => item)
            .join(', ')
        },
      },
      {
        keyName: 'company.contactName',
        title: t('defList.company.contactName'),
        renderCustomEl: (generator: GeneratorDetails) => {
          const filteredMembers = generator.members.filter(
            (member) => member.memberType === 'OWNER',
          )

          if (filteredMembers.length === 0) {
            return undefined
          }

          const { firstName, lastName } = filteredMembers[0]
          return `${firstName} ${lastName}`
        },
      },
      {
        keyName: 'company.phoneNumber',
        title: t('defList.company.phoneNumber'),
        renderCustomEl: (generator: GeneratorDetails) => {
          const filteredMembers = generator.members.filter(
            (member) => member.memberType === 'OWNER',
          )

          if (filteredMembers.length === 0) {
            return undefined
          }

          return filteredMembers[0]?.phoneNumber
        },
      },
      {
        keyName: 'company.associatedCompanies',
        title: t('defList.company.associatedCompanies'),
        renderCustomEl: (generator: GeneratorDetails) => {
          const filteredCompanies = generator.companies.filter(
            (item) => item.id !== generator.parentCompany.id,
          )
          if (filteredCompanies.length === 0) return undefined
          return filteredCompanies.map((item) => item.name).join(', ')
        },
      },
    ]
  }, [generatorDetails])

  /* const rowKeysCompaniesList = useMemo(() => {
    return [
      {
        keyName: 'name',
        title: t('defList.company.associatedCompanies'),
        renderCustomEl: (companyList: Company[]) => {
          return companyList.map((company) => company.name).join(', ')
        },
      },
    ]
  }, [companies]) */

  const handleBlock = async () => {
    if (!generatorDetails || generatorDetails.blocked) return

    try {
      const { error: respError, response } = await fetchData<{
        message: string
      }>({
        method: 'PATCH',
        url: `/core/private/generator/disable/${generatorDetails.id}`,
      })

      if (respError || !response) {
        throw respError
      }

      feedbackMessage(
        { title: tUtils('feedbackMessage.success.title') },
        'success',
      )
      refetch()
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'generator',
        ERR_EXTERNAL_ERROR: ({ message }) => {
          if (message.includes('Auth0 update failed')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.ERR_EXTERNAL_ERROR.update',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    }
  }

  const handleDelete = async () => {
    if (!generatorDetails || !generatorDetails.blocked) return

    try {
      const { error: respError, response } = await fetchData<{
        message: string
      }>({
        method: 'DELETE',
        url: `/core/private/generator/${generatorDetails.id}`,
      })

      if (respError || !response) {
        throw respError
      }

      feedbackMessage(
        { title: tUtils('feedbackMessage.success.title') },
        'success',
      )
      navigate('/generators')
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'generator',
        ERR_EXTERNAL_ERROR: ({ message }) => {
          if (message.includes('Failed to delete user in auth0')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.ERR_EXTERNAL_ERROR.delete',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    }
  }

  const handleUnblock = async () => {
    if (!generatorDetails || !generatorDetails.blocked) return

    try {
      const { error: respError, response } = await fetchData<{
        message: string
      }>({
        method: 'PATCH',
        url: `/core/private/generator/enable/${generatorDetails.id}`,
      })

      if (respError || !response) {
        throw respError
      }

      feedbackMessage(
        { title: tUtils('feedbackMessage.success.title') },
        'success',
      )
      refetch()
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'generator',
        ERR_EXTERNAL_ERROR: ({ message }) => {
          if (message.includes('Auth0 update failed')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.ERR_EXTERNAL_ERROR.update',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    }
  }

  const handleAcceptOnboarding = async () => {
    if (!generatorDetails || generatorDetails.status !== 'REVIEW') return

    try {
      const { error: respError, response } = await fetchData<{
        message: string
      }>({
        method: 'PATCH',
        url: `/core/private/generator/approve/${generatorDetails.id}`,
      })

      if (respError || !response) {
        throw respError
      }

      feedbackMessage(
        { title: tUtils('feedbackMessage.success.title') },
        'success',
      )
      refetch()
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'generator',
        ERR_EXTERNAL_ERROR: ({ message }) => {
          if (message.includes('Auth0 update failed')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.ERR_EXTERNAL_ERROR.update',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    }
  }

  const handleRejectOnboarding = async () => {
    if (!generatorDetails || generatorDetails.status !== 'REVIEW') return

    try {
      const { error: respError, response } = await fetchData<{
        message: string
      }>({
        method: 'PATCH',
        url: `/core/private/generator/reject/${generatorDetails.id}`,
      })

      if (respError || !response) {
        throw respError
      }

      feedbackMessage(
        { title: tUtils('feedbackMessage.success.title') },
        'success',
      )
      navigate('/generators')
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'generator',
        ERR_EXTERNAL_ERROR: ({ message }) => {
          if (message.includes('Auth0 update failed')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.ERR_EXTERNAL_ERROR.update',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    }
  }

  const handleActAs = () => {
    if (generatorDetails === undefined) return
    if (generatorDetails.status !== 'ACTIVE') {
      feedbackMessage(
        {
          title: 'You cannot do this action.',
          description:
            'Users that are not active cannot being used in "acting as".',
        },
        'warn',
      )
      return
    }

    const { id, name, members } = generatorDetails

    const filteredMembers = members.filter(
      (member) => member.memberType === 'OWNER',
    )
    if (filteredMembers.length === 0) {
      throw new Error('Something went wrong, member OWNER could not be found.')
    }

    actAs({
      appMetadata: {
        id,
        memberId: filteredMembers[0].id,
        memberType: filteredMembers[0].memberType,
        name,
        userType: 'GENERATOR',
        status: generatorDetails.status,
        userId: generatorDetails.userId,
        brokerContextName: generatorDetails.brokerContextName,
      },
    })

    navigate('/sites')
  }

  useEffect(() => {
    if (error) {
      detailsThrowFeedbackError({ err: error })
    }
  }, [error])

  useEffect(() => {
    if (!generatorId) {
      navigate('/generators')
    }
  }, [generatorId])

  return (
    <section
      className={classNames(
        container,
        breakPointsContainer,
        'items-center pt-12 relative gap-y-8',
      )}
    >
      <div className="absolute top-0 left-0 -mx-3 hover:brightness-50 cursor-pointer">
        <Button
          variant="text"
          icon={<ChevronLeftIcon />}
          onClick={() => navigate('/generators')}
        >
          {t('backButton')}
        </Button>
      </div>

      {isLoading && (
        <div className="w-full">
          <Spinner className="mx-auto animate-spin w-5 h-5" />
        </div>
      )}

      {!isLoading && error && (
        <div>
          <strong>{t('error.title')}</strong>

          <Button variant="primary" onClick={() => refetch()}>
            {t('error.button')}
          </Button>
        </div>
      )}

      {!isLoading && !error && generatorDetails && (
        <>
          <div className="flex items-center justify-between w-full">
            <div className="flex flex-1 items-center justify-start mt-5 gap-x-3">
              <h1 className="text-3xl font-semibold text-ppa/title mr-4">
                {generatorDetails.name}
              </h1>
              <UserPill
                status={generatorDetails.status}
                blocked={generatorDetails.blocked}
              />
              {generatorDetails.blocked && (
                <div className="flex items-center gap-x-2 font-semibold text-xs">
                  <span>{t('userBlocked')}</span>
                  <BanIcon className="w-5 h-5 text-ppa/redError" />
                </div>
              )}
            </div>
            <Dropdown
              content={
                <div className="flex flex-col items-start">
                  {generatorDetails.blocked ? (
                    <>
                      <Button
                        variant="text"
                        icon={<BadgeCheckIcon />}
                        onClick={handleUnblock}
                        data-testid="button-unblock"
                      >
                        {t('actionsDropdown.unblock')}
                      </Button>
                      <Button
                        variant="text"
                        icon={<TrashIcon />}
                        onClick={handleDelete}
                        data-testid="button-delete"
                      >
                        {t('actionsDropdown.delete.button')}
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="text"
                      icon={<BanIcon />}
                      onClick={handleBlock}
                      data-testid="button-block"
                    >
                      {t('actionsDropdown.block')}
                    </Button>
                  )}
                  {generatorDetails.status === 'REVIEW' && (
                    <>
                      <Button
                        variant="text"
                        icon={<CheckIcon />}
                        onClick={handleAcceptOnboarding}
                        data-testid="button-accept-onboarding"
                      >
                        {t('actionsDropdown.accept')}
                      </Button>
                      <Button
                        variant="text"
                        icon={<XCircleIcon />}
                        onClick={handleRejectOnboarding}
                        data-testid="button-reject-onboarding"
                      >
                        {t('actionsDropdown.reject')}
                      </Button>
                    </>
                  )}
                  {generatorDetails.status === 'ACTIVE' && (
                    <Button
                      variant="text"
                      icon={<UserIcon />}
                      onClick={handleActAs}
                      data-testid="button-act-as"
                    >
                      {t('actionsDropdown.actAs')}
                    </Button>
                  )}
                </div>
              }
            />
          </div>
          <div className="flex flex-col mr-auto">
            <h2 className="mt-2 mb-5 text-ppa/title text-lg font-medium">
              {t('defList.company.title')}
            </h2>
            <DefList rowKeys={rowKeysCompany} data={data} />
            {/* <DefList rowKeys={rowKeysCompaniesList} data={companies} /> */}
          </div>

          {generatorDetails.members.length > 0 && (
            <div className="flex flex-col mr-auto gap-y-2 text-ppa/title text-lg font-medium">
              <h2 className="mt-2 mb-5">
                {t('usersInOrganisationDetails.title')}
              </h2>
              <Table
                headers={[
                  t('table.headers.userDetails'),
                  t('table.headers.phoneNumber'),
                  t('table.headers.memberType'),
                  isFeatureEnabled('memberStatusAdmin') &&
                    t('table.headers.status'),
                ]}
                rowKeys={[
                  ...rowKeys,
                  {
                    keyName: 'memberType',
                    renderCustomEl: (member: Member) =>
                      t(`table.body.memberType.${member.memberType}`),
                  },
                  {
                    keyName: 'action',
                    renderCustomEl: (item) => {
                      return (
                        <Dropdown
                          orientation="bottom-left"
                          content={
                            <div className="flex flex-col gap-y-1.5 text-ppa/title text-xs font-normal">
                              <button
                                type="button"
                                onClick={() => handleDelete()}
                                className="flex items-center"
                              >
                                <TrashIcon className="w-4 h-4 mr-2.5" />
                                {t('table.body.actions.deleteUser')}
                              </button>
                              <button
                                type="button"
                                onClick={() => {
                                  setSelectedUserId(item.userId)
                                  setIsMemberTypeDrawerOpen(true)
                                }}
                                className="flex items-center"
                              >
                                <MemberIcon className="w-4 h-4 mr-2.5" />
                                {t('table.body.actions.changeMemberType')}
                              </button>
                            </div>
                          }
                        />
                      )
                    },
                    customColumnClass: 'py-6',
                  },
                ]}
                isLoading={isLoading}
                data={generatorDetails.members}
              />
            </div>
          )}
          {user.appMetadata.brokerContextName === 'PPAYA' && (
            <>
              <div className="flex flex-col mr-auto gap-y-5">
                <h3 className="text-2xl font-semibold text-ppa/title">
                  {t('brokerFee.title')}
                </h3>
                {(!generatorDetails?.brokerFeeRuleGroups ||
                  generatorDetails.brokerFeeRuleGroups.length === 0) && (
                  <span className="text-ppa/title">
                    {t('brokerFee.notFound')}
                  </span>
                )}
                {generatorDetails?.brokerFeeRuleGroups &&
                  generatorDetails.brokerFeeRuleGroups.length > 0 &&
                  generatorDetails.brokerFeeRuleGroups
                    .sort((a, b) => (a.priority > b.priority ? -1 : 1))
                    .map((group, idx) => (
                      <div key={group.id} className="flex flex-col gap-y-2">
                        <strong className="text-xl font-medium text-ppa/subtitle">
                          Rule {idx + 1}
                        </strong>
                        <div className="flex flex-col gap-x-4 pl-3 border border-ppa/grayBorder p-4 mx-4 rounded-md">
                          {group.conditions.map((condition) => (
                            <div
                              key={`${condition.field}-${condition.operator}-${condition.value}`}
                              className="flex items-center text-ppa/subtitle gap-x-2"
                            >
                              <span className="w-[100px]">
                                {capitalizeText(condition.field)}
                              </span>
                              <span className="w-[40px]">
                                {condition.operator === '>' && (
                                  <ChevronRightIcon className="w-5 h-5" />
                                )}
                                {condition.operator === '<' && (
                                  <ChevronLeftIcon className="w-5 h-5" />
                                )}
                                {condition.operator === '=' && (
                                  <MenuAlt4Icon className="w-5 h-5" />
                                )}
                              </span>
                              <span className="text-left">
                                {condition.value}
                                {condition.field === 'capacity' && ' MW'}
                                {condition.field === 'voltage' && ' kV'}
                              </span>
                            </div>
                          ))}
                        </div>
                        <span className="font-medium text-lg pl-3 text-ppa/title flex items-center justify-end gap-x-2">
                          {t('brokerFee.list.brokerFee')}
                          <strong className="text-ppa/primary">
                            {group.brokerFee} £/MWh
                          </strong>
                        </span>
                      </div>
                    ))}
                <div className="max-w-[200px]">
                  <Button
                    variant="primary"
                    onClick={() => setIsBrokerDrawerOpen(true)}
                  >
                    {t('brokerFee.button')}
                  </Button>
                </div>
              </div>
              <BrokerFeeDrawer
                generatorId={generatorDetails.id}
                isOpen={isBrokerDrawerOpen}
                onSuccess={() => {
                  setIsBrokerDrawerOpen(false)
                  refetch()
                }}
                brokerFee={generatorDetails?.brokerFeeRuleGroups}
                handleClose={() => setIsBrokerDrawerOpen(false)}
              />
              <ChangeMemberTypeDrawer
                userType="GENERATOR"
                userId={selectedUserId}
                isOpen={isMemberTypeDrawerOpen}
                onSuccess={() => {
                  setIsMemberTypeDrawerOpen(false)
                  refetch()
                }}
                handleClose={() => setIsMemberTypeDrawerOpen(false)}
              />
            </>
          )}
        </>
      )}
    </section>
  )
}

export default Details
