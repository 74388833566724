import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

import usePPAGetList from '../../../../../../../hooks/use-ppa/use-ppa-get-list'

import { validateDecimalNumbers } from '../../../../../../../utils'

import Input from '../../../../../../../components/atoms/input'
import Message from '../../../../../../../components/atoms/message'

import InputGroup from '../../../../../../../components/molecules/input-group'

import { SupplierTenderBid, ChargeType } from '../../../../../../../types'

type ChargePowerPriceType = 'MWH' | 'MONTHLY' | 'DAILY'

interface FormCharge {
  type: ChargePowerPriceType
  value: string
}

export interface FormCharges {
  RISK_PREMIUM: FormCharge
  DA_DC: FormCharge
  THIRD_PARTY: FormCharge
  OTHER: FormCharge
}

type ChargeKeys = keyof FormCharges

export type FormValues = {
  charges?: FormCharges
}

export interface FormProps {
  bid?: SupplierTenderBid
}

const ChargesForm: React.FC<FormProps> = ({ bid }) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.sleevedTenders.details.form',
  })

  const { isLoading, data, error } = usePPAGetList<ChargeType>({
    dataKey: 'activeCharges',
    path: '/core/private/supplier-tender-bid-charge/list',
  })

  const [chargesOptions, setChargesOptions] = useState<ChargeType[]>([])

  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<FormValues>()

  useEffect(() => {
    if (isLoading || error || !data) return

    const uniqueChargeNames = new Set()
    const uniqueCharges = data.filter((item: any) => {
      if (
        item.active &&
        item.name !== 'BROKER_FEE' &&
        !uniqueChargeNames.has(item.name)
      ) {
        uniqueChargeNames.add(item.name)
        return true
      }
      return false
    })

    setChargesOptions(uniqueCharges)

    uniqueCharges.forEach((charge) => {
      const chargeName = charge.name as ChargeKeys

      if (
        bid?.supplierTenderBidCharges &&
        bid.supplierTenderBidCharges.length
      ) {
        const matchingCharge = bid.supplierTenderBidCharges.find(
          (char) => char.name === charge.name,
        )

        if (matchingCharge) {
          const value = `${matchingCharge?.value || 0}`
          const type = (matchingCharge?.type || 'MWH') as ChargePowerPriceType

          setValue(`charges.${chargeName}`, { type, value })
        } else {
          setValue(`charges.${chargeName}`, { type: 'MWH', value: '0' })
        }
      } else {
        setValue(`charges.${chargeName}`, { type: 'MWH', value: '0' })
      }
    })
  }, [data, isLoading, error])

  useEffect(() => {
    if (error) {
      console.error('Charges could not be loaded', error)
    }
  }, [error])

  return (
    <div>
      <div className="border border-b border-ppa/grayBorder w-full" />
      <div className="mt-3.5">
        <InputGroup label={t('fields.charges.label')}>
          <Message variant="info" size="text-xs self-center">
            {t('fields.charges.infoMessage')}
            <br />
            {t('fields.charges.warnMessage')}
          </Message>
        </InputGroup>
        <div className="flex flex-wrap mt-1 gap-y-3 max-w-[450px]">
          {chargesOptions.map(({ name }) => {
            const thisRegister = {
              ...register(`charges.${name}.value` as any),
              ref: undefined,
            }
            return (
              <Controller
                {...thisRegister}
                key={name}
                rules={{
                  validate: {
                    isValidDecimal: validateDecimalNumbers,
                  },
                }}
                render={({ field: { ...props } }) => {
                  const selectRegister = {
                    ...register(`charges.${name}.type` as any),
                    ref: undefined,
                  }
                  return (
                    <InputGroup
                      label={t(`fields.charges.${name}.label`)}
                      error={
                        errors?.charges?.[name as ChargeKeys]?.value?.message
                      }
                    >
                      <div className="w-48">
                        <Input
                          {...props}
                          type="text"
                          placeholder="0,00"
                          suffix={
                            <Controller
                              {...selectRegister}
                              render={({ field: { ...selectProps } }) => (
                                <select
                                  {...selectProps}
                                  className="text-right items-center pr-1"
                                  data-testid={`charges.${name}.chargeType`}
                                >
                                  <option value="MWH">£/MWh</option>
                                  <option value="MONTHLY">£/mo</option>
                                  <option value="DAILY">£/day</option>
                                </select>
                              )}
                            />
                          }
                          error={
                            errors?.charges?.[name as ChargeKeys]?.value
                              ?.message
                          }
                        />
                      </div>
                    </InputGroup>
                  )
                }}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ChargesForm
