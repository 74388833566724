/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */

import React from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { transition } from '../../../styles'

import Button from '../../atoms/button'

interface BidSubsidiesInfoModalProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  subsidyName: string
}

const BidSubsidiesInfoModal: React.FC<BidSubsidiesInfoModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  subsidyName,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.tenders.details.bids.subsidiesInfoModal',
  })

  return (
    <div>
      <div
        className={classNames(
          transition,
          'flex fixed w-screen h-screen overflow-hidden inset-0 z-[1000]',
          'bg-ppa/lightBlackShadow cursor-default',
          isOpen
            ? 'duration-500 opacity-100'
            : 'duration-500 opacity-0 pointer-events-none select-none',
        )}
        onClick={onClose}
        role="button"
      />
      <div
        onClick={onClose}
        className={classNames(
          transition,
          'w-full',
          'flex items-center justify-center',
          'fixed w-screen h-screen inset-0 z-[1050]',
          'bg-transparent cursor-default',
          isOpen ? 'translate-y-0' : '-translate-y-full',
        )}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className={classNames(
            transition,
            'w-auto h-auto m-5 sm:m-10 md:w-[460px] md:m-auto',
            'bg-white rounded-md cursor-default max-h-screen overflow-y-auto',
            isOpen ? 'scale-1' : 'scale-0',
          )}
        >
          <div className="flex flex-col gap-y-5 p-7">
            <p className="text-ppa/title text-sm font-normal">
              {t('description', {
                name: subsidyName,
              })}
            </p>
            <div className="flex justify-end">
              <div className="flex gap-x-3 w-48">
                <Button variant="quaternary" onClick={onConfirm} fullWidth>
                  {t('no')}
                </Button>
                <Button variant="primary" onClick={onClose} fullWidth>
                  {t('yes')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BidSubsidiesInfoModal
