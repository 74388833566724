import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import usePPA from '../../../../../../hooks/use-ppa'

import { feedbackMessage } from '../../../../../../components/atoms/feedback'

import FormBill, { BillFormRef, FormValues } from '../form-bill'

interface UploadBillProps {
  idMeter?: string
  isDrawerOpen: boolean
  onSuccess: () => void
  onCancel: () => void
}

const UploadBill: React.FC<UploadBillProps> = ({
  idMeter,
  isDrawerOpen,
  onSuccess,
  onCancel,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'consumers.meters.billDrawer',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const billForm = useRef<BillFormRef>(null)

  const { fetchData, throwFeedbackError } = usePPA()

  const [isLoading, setIsLoading] = useState(false)

  const handleUploadBill = async (data: FormValues) => {
    if (isLoading) return
    setIsLoading(true)

    try {
      if (!idMeter) throw Error('Missing meter ID')

      const formData = new FormData()
      formData.append('file', data.billFile)
      formData.append('month', data.month.value)
      formData.append('year', data.year.value)
      formData.append('meterId', idMeter)

      const { error } = await fetchData({
        method: 'POST',
        url: '/core/private/consumer-meter/consumer/bill',
        body: formData,
        headers: {
          'Content-type': 'multipart/form-data',
        },
      })

      if (error) throw error

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('onUpload.success.description'),
        },
        'success',
      )

      onSuccess()
      billForm.current?.resetForm()
    } catch (err) {
      throwFeedbackError({ err })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="flex flex-col gap-y-5 sm:w-[640px]">
      <h1 className="text-xl font-bold text-ppa/title">{t('uploadTitle')}</h1>

      {isDrawerOpen && (
        <FormBill
          isLoading={isLoading}
          onSubmit={handleUploadBill}
          onSkip={onCancel}
        />
      )}
    </div>
  )
}

export default UploadBill
