/* eslint-disable react/jsx-no-useless-fragment */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import useUser from '../use-user'
import usePPA from '../use-ppa'

import RegisterNewCompanyForm, { Company } from './form-register-company'

import SlidingDrawer from '../../components/molecules/sliding-drawer'

interface UseCompanyProps {
  onSuccess: (company: Company) => void
}

interface UseCompanyFn {
  drawer: JSX.Element
  openDrawer: () => void
}

const useRegisterCompany = ({ onSuccess }: UseCompanyProps): UseCompanyFn => {
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { user } = useUser()

  const { fetchData, throwFeedbackError } = usePPA()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)

  const handleCreateCompany = async (company: Company): Promise<void> => {
    if (isLoading) return
    setIsLoading(true)

    try {
      const { error } = await fetchData({
        method: 'POST',
        url: `/core/private/${user.appMetadata.userType.toLowerCase()}/company/`,
        body: {
          ...company,
          number: company?.number || null,
          type: company?.type || null,
          address: {
            ...company.address,
            addressLine2: company.address?.addressLine2 || null,
            region: company.address?.region || null,
          },
          billingAddress: {
            ...company.billingAddress,
            region: company.address?.region || null,
            addressLine2: company.billingAddress?.addressLine2 || null,
          },
        },
      })

      if (error) throw error
      onSuccess(company)
      setIsDrawerOpen(false)
    } catch (err) {
      throwFeedbackError({
        err,
        context: `${user.appMetadata.userType.toLowerCase()}Company`,
        ERR_DB_CONSTRAINT: ({ message }) => {
          if (message.includes('Company already exists')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                `throwFeedbackError.errorCodes.${user.appMetadata.userType.toLowerCase()}Company.ERR_DB_CONSTRAINT.companyAlreadyExists`,
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    }

    setIsLoading(false)
  }

  const openDrawer = () => {
    setIsDrawerOpen(true)
  }

  const drawer = (
    <>
      {isDrawerOpen && (
        <SlidingDrawer
          isOpen={isDrawerOpen}
          handleClose={() => setIsDrawerOpen(false)}
          className="z-[500]"
        >
          <RegisterNewCompanyForm
            handleSubmitCompany={handleCreateCompany}
            handleClose={() => setIsDrawerOpen(false)}
            isLoading={isLoading}
          />
        </SlidingDrawer>
      )}
    </>
  )

  return { drawer, openDrawer }
}

export default useRegisterCompany
