import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { PlusIcon } from '@heroicons/react/solid'

import dayjs from 'dayjs'

import usePPA from '../../../../../../hooks/use-ppa'

import SlidingDrawer from '../../../../../../components/molecules/sliding-drawer'
import { feedbackMessage } from '../../../../../../components/atoms/feedback'
import { SelectOptions } from '../../../../../../components/atoms/select'
import Message from '../../../../../../components/atoms/message'
import Button from '../../../../../../components/atoms/button'

import { dateFormats } from '../../../../../../utils/data'

import TenderForm, { FormValues } from '../form-tender'

interface CreateTenderProps {
  onSuccess: () => void
}

const CreateTender: React.FC<CreateTenderProps> = ({ onSuccess }) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'consumers.tenders',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const navigate = useNavigate()

  const { fetchData, throwFeedbackError } = usePPA()

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => {
    setIsLoading(false)
    setIsDrawerOpen(false)
  }

  const onTenderCreateSubmit = async (formData: FormValues) => {
    if (isLoading) return
    setIsLoading(true)

    try {
      const consumerMeterIds = formData.meter.map(
        ({ value }: SelectOptions) => {
          return JSON.parse(value as string).id
        },
      )

      let supplierIds: string[] = []

      if (
        formData.participatingSuppliers &&
        formData.participatingSuppliers.length > 0
      ) {
        supplierIds = formData.participatingSuppliers.map(
          (item: SelectOptions) => JSON.parse(item.value as string).id,
        )
      }

      const {
        startDate,
        durationMonths,
        customEndDate,
        otherRequirements,
        contractType,
        paymentMethod,
        targetMatch,
        greenTariff,
      } = formData

      let { endDate } = formData
      if (customEndDate) {
        endDate = formData.endDate
      } else {
        endDate = dayjs(startDate)
          .add(durationMonths?.value as any, 'months')
          .subtract(1, 'month')
          .endOf('month')
          .format(dateFormats.aws)
      }

      const { response, error: err } = await fetchData({
        method: 'POST',
        url: '/core/private/consumer-tender/consumer',
        body: {
          startDate,
          endDate,
          consumerMeterIds,
          preferredContractType: contractType?.value,
          greenTariff,
          paymentMethod: paymentMethod?.value,
          otherRequirements,
          targetMatch: targetMatch?.value || null,
          supplierIds,
        },
      })

      if (err || !response) throw err

      onSuccess()
      handleClose()

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t(
            'drawerCreateUpdateTender.form.create.onSubmit.success.description',
          ),
        },
        'success',
      )

      navigate('/tenders/status/draft')
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'consumerTender',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="flex items-center text-">
      <div className="ml-9">
        <Button
          variant="primary"
          icon={<PlusIcon />}
          onClick={() => setIsDrawerOpen(true)}
        >
          {t('page.title.addTender')}
        </Button>

        <SlidingDrawer isOpen={isDrawerOpen} handleClose={handleClose}>
          <div className="flex flex-col gap-y-5 sm:w-[640px]">
            <h1 className="text-xl font-bold text-ppa/title">
              {t('drawerCreateUpdateTender.form.create.title')}
            </h1>

            <Message variant="info" size="text-sm">
              {t('drawerCreateUpdateTender.form.create.info.0')}
              <br />
              {t('drawerCreateUpdateTender.form.create.info.1')}
            </Message>

            {isDrawerOpen && (
              <TenderForm
                isLoading={isLoading}
                onCancel={handleClose}
                onSubmit={onTenderCreateSubmit}
              />
            )}
          </div>
        </SlidingDrawer>
      </div>
    </div>
  )
}

export default CreateTender
