import React from 'react'

import TenderDetails from '../components/details'

import { SleevedTenderDetailsProps } from '../details'

const PendingSleevedTenderDetails: React.FC<SleevedTenderDetailsProps> = ({
  tender,
}) => {
  return (
    <section className="flex flex-col gap-y-8">
      <TenderDetails tender={tender} />
    </section>
  )
}

export default PendingSleevedTenderDetails
