import SleevedTendersActive from './active'
import SleevedTendersActiveDetails from './active/details'

import SleevedTendersPending from './pending'
import SleevedTendersPendingDetails from './pending/details'

import SleevedTendersWithdrawn from './withdrawn'
import WithdrawnSleevedTenderDetails from './withdrawn/details'

import SleevedTendersExecuted from './executed'
import SleevedTendersExecutedDetails from './executed/details'

import SleevedTendersUnderOffer from './under-offer'
import SleevedTendersUnderOfferDetails from './under-offer/details'

export const sleevedTendersLinks = [
  { to: '/status/active', label: 'tenderStatus.active' },
  { to: '/status/pending', label: 'tenderStatus.pending' },
  { to: '/status/withdrawn', label: 'tenderStatus.withdrawn' },
  { to: '/status/underOffer', label: 'tenderStatus.underOffer' },
  { to: '/status/executed', label: 'tenderStatus.executed' },
]

export const sleevedTendersSubRoutes = {
  active: {
    index: <SleevedTendersActive />,
    details: SleevedTendersActiveDetails,
  },
  pending: {
    index: <SleevedTendersPending />,
    details: SleevedTendersPendingDetails,
  },
  withdrawn: {
    index: <SleevedTendersWithdrawn />,
    details: WithdrawnSleevedTenderDetails,
  },
  underOffer: {
    index: <SleevedTendersUnderOffer />,
    details: SleevedTendersUnderOfferDetails,
  },
  executed: {
    index: <SleevedTendersExecuted />,
    details: SleevedTendersExecutedDetails,
  },
}
