import React from 'react'

import classNames from 'classnames'

import { ConsumerTenderDetailsProps } from '../details'

import TenderDetails from '../components/details'

const ConsumerTendersPendingDetails: React.FC<ConsumerTenderDetailsProps> = ({
  tenderInvitation,
}) => {
  return (
    <div className="flex flex-col 2xl:flex-row">
      <div className="flex-1">
        <TenderDetails tender={tenderInvitation} />
      </div>
      <div
        className={classNames(
          'border-ppa/grayBorder',
          'border-t mt-10 pt-10',
          '2xl:border-t-0 2xl:border-l 2xl:mt-10 2xl:pt-0 2xl:ml-10 2xl:pl-10',
          'flex-1',
        )}
      />
    </div>
  )
}

export default ConsumerTendersPendingDetails
