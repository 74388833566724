import React from 'react'

import FormUserPreferences, {
  FormPreferences,
  MappedPreference,
  defaultActionNotifications,
} from '../../../user-preferences'

const mappedPreferences: MappedPreference[] = [
  {
    context: 'TENDER',
    title: 'tenders',
    notifications: [
      {
        action: 'CREATED',
        description: 'tenderCreated',
        ...defaultActionNotifications,
      },
      {
        action: 'ACTIVATED',
        description: 'tenderActivated',
        ...defaultActionNotifications,
      },
      {
        action: 'SET_DEADLINE',
        description: 'tenderDeadline',
        ...defaultActionNotifications,
      },
      {
        action: 'TARGET_PRICE_REACHED',
        description: 'tenderTargetPrice',
        ...defaultActionNotifications,
      },
      {
        action: 'EXECUTED',
        description: 'tenderExecuted',
        ...defaultActionNotifications,
      },
    ],
  },
  {
    context: 'CONSUMER_TENDER',
    title: 'importTenders',
    notifications: [
      {
        action: 'CREATED',
        description: 'tenderCreated',
        ...defaultActionNotifications,
      },
      {
        action: 'ACTIVATED',
        description: 'tenderActivated',
        ...defaultActionNotifications,
      },
      {
        action: 'SET_DEADLINE',
        description: 'tenderDeadline',
        ...defaultActionNotifications,
      },
      {
        action: 'EXECUTED',
        description: 'tenderExecuted',
        ...defaultActionNotifications,
      },
    ],
  },
  {
    context: 'SUPPLIER_TENDER',
    title: 'sleevedTenders',
    notifications: [
      {
        action: 'CREATED',
        description: 'tenderCreated',
        ...defaultActionNotifications,
      },
      {
        action: 'ACTIVATED',
        description: 'tenderActivated',
        ...defaultActionNotifications,
      },
      {
        action: 'SET_DEADLINE',
        description: 'tenderDeadline',
        ...defaultActionNotifications,
      },
      {
        action: 'EXECUTED',
        description: 'tenderExecuted',
        ...defaultActionNotifications,
      },
    ],
  },
  {
    context: 'BID',
    title: 'bids',
    notifications: [
      {
        action: 'CREATED',
        description: 'bidCreated',
        ...defaultActionNotifications,
      },
      {
        action: 'ACCEPTED',
        description: 'bidAccepted',
        ...defaultActionNotifications,
      },
      {
        action: 'REVOKED',
        description: 'bidRevoke',
        ...defaultActionNotifications,
      },
    ],
  },
  {
    context: 'CONSUMER_TENDER_BID',
    title: 'importBids',
    notifications: [
      {
        action: 'CREATED',
        description: 'bidCreated',
        ...defaultActionNotifications,
      },
      {
        action: 'ACCEPTED',
        description: 'bidAccepted',
        ...defaultActionNotifications,
      },
      {
        action: 'REVOKED',
        description: 'bidRevoke',
        ...defaultActionNotifications,
      },
    ],
  },
  {
    context: 'SUPPLIER_TENDER_BID',
    title: 'sleevedBids',
    notifications: [
      {
        action: 'CREATED',
        description: 'bidCreated',
        ...defaultActionNotifications,
      },
      {
        action: 'ACCEPTED',
        description: 'bidAccepted',
        ...defaultActionNotifications,
      },
      {
        action: 'REVOKED',
        description: 'bidRevoke',
        ...defaultActionNotifications,
      },
    ],
  },
]

const formDefaultValues: FormPreferences<'ADMIN'> = {
  notifications: {
    TENDER: {
      CREATED: defaultActionNotifications,
      ACTIVATED: defaultActionNotifications,
      SET_DEADLINE: defaultActionNotifications,
      TARGET_PRICE_REACHED: defaultActionNotifications,
      EXECUTED: defaultActionNotifications,
    },
    CONSUMER_TENDER: {
      CREATED: defaultActionNotifications,
      ACTIVATED: defaultActionNotifications,
      SET_DEADLINE: defaultActionNotifications,
      EXECUTED: defaultActionNotifications,
    },
    SUPPLIER_TENDER: {
      CREATED: defaultActionNotifications,
      ACTIVATED: defaultActionNotifications,
      SET_DEADLINE: defaultActionNotifications,
      EXECUTED: defaultActionNotifications,
    },
    BID: {
      CREATED: defaultActionNotifications,
      ACCEPTED: defaultActionNotifications,
      REVOKED: defaultActionNotifications,
    },
    CONSUMER_TENDER_BID: {
      CREATED: defaultActionNotifications,
      ACCEPTED: defaultActionNotifications,
      REVOKED: defaultActionNotifications,
    },
    SUPPLIER_TENDER_BID: {
      CREATED: defaultActionNotifications,
      ACCEPTED: defaultActionNotifications,
      REVOKED: defaultActionNotifications,
    },
  },
}

const Notifications: React.FC = () => {
  return (
    <FormUserPreferences
      mappedPreferences={mappedPreferences}
      defaultFormValues={formDefaultValues}
    />
  )
}

export default Notifications
