/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

import usePPAGetList from '../../../../../../../hooks/use-ppa/use-ppa-get-list'

import { validateDecimalNumbers } from '../../../../../../../utils'

import { Bid, SubsidyType, PowerPriceType } from '../../../../../../../types'

import Input from '../../../../../../../components/atoms/input'
import Message from '../../../../../../../components/atoms/message'

import InputGroup from '../../../../../../../components/molecules/input-group'

import { TenderInvitation } from '../../details'

interface FormSubsidy {
  enabled?: boolean | string
  type: PowerPriceType
  value: string
}

export interface FormSubsidies {
  REGO: FormSubsidy
  ROC_BUYOUT: FormSubsidy
  ROC_RECYCLE: FormSubsidy
  FIT: FormSubsidy
}

type SubsidyKeys = keyof FormSubsidies

export type FormValues = {
  subsidies?: FormSubsidies
}

interface SubsidiesFormProps {
  tender: TenderInvitation['tender']
  bid?: Bid
}

const SubsidiesForm: React.FC<SubsidiesFormProps> = ({ tender, bid }) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.tenders.details.bids.form',
  })

  const { isLoading, data, error } = usePPAGetList<SubsidyType>({
    dataKey: 'activeSubsidies',
    path: '/core/private/subsidy/list',
  })

  const [subsidiesOptions, setSubsidiesOptions] = useState<SubsidyType[]>([])

  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<FormValues>()

  const activeSubsidiesNames = useMemo(() => {
    return tender.subsidies
      .filter((item) => item.toggled === true)
      .map((item) => item.name)
  }, [tender])

  useEffect(() => {
    if (isLoading || error || !data || subsidiesOptions.length > 0) return
    if (!activeSubsidiesNames || activeSubsidiesNames.length === 0) return

    const hasROC = activeSubsidiesNames.includes('ROC')

    const subsidiesList: SubsidyType[] = data
      .filter((item) => activeSubsidiesNames.includes(item.name))
      .sort((a, b) => {
        return a.order > b.order ? 1 : -1
      })

    if (hasROC) {
      subsidiesList.push(
        { name: 'ROC_BUYOUT', active: true, order: 1 } as any,
        { name: 'ROC_RECYCLE', active: true, order: 2 } as any,
      )
    }

    setSubsidiesOptions(subsidiesList)

    subsidiesList.forEach(({ name: subsidyName }) => {
      if (bid) {
        const matchingSubsidy = bid.subsidies.find(
          (sub) => sub.name === subsidyName,
        )

        if (matchingSubsidy) {
          const value = `${matchingSubsidy.value || 0}`
          const type = (matchingSubsidy.type || 'FIXED') as PowerPriceType
          if (subsidyName === 'FIT') {
            setValue(`subsidies.${subsidyName}`, {
              type: 'FLEXIBLE',
              value: '100',
            })
          } else {
            setValue(`subsidies.${subsidyName as SubsidyKeys}`, { type, value })
          }
        }
      } else {
        let type: PowerPriceType
        if (subsidyName === 'REGO') {
          type = 'FIXED'
        } else {
          type = 'FLEXIBLE'
        }
        const value = subsidyName === 'FIT' ? '100' : '0'
        setValue(`subsidies.${subsidyName as SubsidyKeys}`, { type, value })
      }
    })
  }, [data, isLoading, error])

  useEffect(() => {
    if (error) {
      console.error('Subsidies could not be loaded', error)
      /**
       * @SILENCED_ERROR
       * Change this later on.
       */
      // throwFeedbackError(`getData.errors.${error.errorType}`)
    }
  }, [error])

  return (
    <>
      {subsidiesOptions.length > 0 && (
        <div>
          <div className="border border-b border-ppa/grayBorder w-full" />
          <div className="mt-3.5">
            <InputGroup label={t('fields.subsidies.label')}>
              <Message variant="info" size="text-xs self-center">
                {t('fields.subsidies.infoMessage')}
              </Message>
            </InputGroup>
            <div className="flex flex-col mt-1 gap-y-3">
              {subsidiesOptions.map(({ name }) => {
                const thisRegister = {
                  ...register(`subsidies.${name}.value` as any),
                  ref: undefined,
                }
                return (
                  <div key={name}>
                    <Controller
                      {...thisRegister}
                      rules={{
                        validate: {
                          isValidDecimal: validateDecimalNumbers,
                        },
                      }}
                      render={({ field: { ...props } }) => {
                        return (
                          <InputGroup
                            label={
                              <>
                                <span>
                                  {t(`fields.subsidies.${name}.label`)}
                                </span>
                                <span className="text-xs font-light ml-2 mt-[2px]">
                                  {t(`fields.subsidies.${name}.sublabel`)}
                                </span>
                              </>
                            }
                            error={
                              errors?.subsidies?.[name as SubsidyKeys]?.value
                                ?.message
                            }
                          >
                            <div className="max-w-[170px]">
                              <Input
                                {...props}
                                type="text"
                                placeholder="0,00"
                                suffix={name !== 'REGO' ? '%' : '£/MWh'}
                                max={
                                  ['ROC_BUYOUT', 'ROC_RECYCLE'].includes(name)
                                    ? '100'
                                    : undefined
                                }
                                readOnly={name === 'FIT'}
                                disabled={name === 'FIT'}
                                disabledInput={name === 'FIT'}
                                error={
                                  errors?.subsidies?.[name as SubsidyKeys]
                                    ?.value?.message
                                }
                              />
                            </div>
                          </InputGroup>
                        )
                      }}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SubsidiesForm
