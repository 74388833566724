import React from 'react'

import FormUserPreferences, {
  FormPreferences,
  MappedPreference,
  defaultActionNotifications,
} from '../../../user-preferences'

const mappedPreferences: MappedPreference[] = [
  {
    context: 'CONSUMER_TENDER',
    title: 'importTenders',
    notifications: [
      {
        action: 'SET_DEADLINE',
        description: 'tenderDeadline',
        ...defaultActionNotifications,
      },
      {
        action: 'EXECUTED',
        description: 'tenderExecuted',
        ...defaultActionNotifications,
      },
      {
        action: 'LOST',
        description: 'tenderLost',
        ...defaultActionNotifications,
      },
    ],
  },
  {
    context: 'CONSUMER_TENDER_BID',
    title: 'importBids',
    notifications: [
      {
        action: 'CREATED',
        description: 'bidCreated',
        ...defaultActionNotifications,
      },
    ],
  },
]

const formDefaultValues: FormPreferences<'CONSUMER'> = {
  notifications: {
    CONSUMER_TENDER: {
      EXECUTED: defaultActionNotifications,
      SET_DEADLINE: defaultActionNotifications,
      LOST: defaultActionNotifications,
    },
    CONSUMER_TENDER_BID: {
      CREATED: defaultActionNotifications,
    },
  },
}

const Notifications: React.FC = () => {
  return (
    <FormUserPreferences
      mappedPreferences={mappedPreferences}
      defaultFormValues={formDefaultValues}
    />
  )
}

export default Notifications
