import { useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'

import classNames from 'classnames'
import dayjs from 'dayjs'

import useUser from '../../../../../../hooks/use-user'
import { transition } from '../../../../../../styles'

import RequiredField from '../../../../../../components/atoms/required-form-field'
import Button from '../../../../../../components/atoms/button'
import Textarea from '../../../../../../components/atoms/text-area'
import Checkbox from '../../../../../../components/atoms/checkbox'
import Input from '../../../../../../components/atoms/input'
import InputGroup from '../../../../../../components/molecules/input-group'
import Select, {
  SelectOptions,
} from '../../../../../../components/atoms/select'
import Message from '../../../../../../components/atoms/message'

import SelectSites from '../select-sites'
import SelectSuppliers from '../select-suppliers'

import { dateFormats } from '../../../../../../utils/data'

export const optionsDurations: SelectOptions[] = [
  { value: '6', label: '6' },
  { value: '12', label: '12' },
  { value: '24', label: '24' },
  { value: '36', label: '36' },
  { value: '48', label: '48' },
  { value: '60', label: '60' },
]

export type FormValues = {
  startDate?: string
  durationMonths?: SelectOptions
  endDate?: string
  customEndDate: boolean
  sites: SelectOptions[]
  participatingSuppliers: SelectOptions[]
  subsidies: {
    fit: { enabled?: boolean | string }
    rego: { enabled?: boolean | string }
    roc: { enabled?: boolean | string }
  }
  otherRequirements?: string
}

interface TenderFormProps {
  onSubmit: (data: any) => void
  onCancel?: () => void
  isLoading?: boolean
  defaultValues?: FormValues
}

const formDefaultValues: FormValues = {
  startDate:
    dayjs().get('hours') < 10
      ? dayjs().add(1, 'week').format(dateFormats.aws)
      : dayjs().add(8, 'days').format(dateFormats.aws),
  durationMonths: undefined,
  endDate: '',
  customEndDate: true,
  sites: [],
  participatingSuppliers: [],
  subsidies: {
    fit: { enabled: false },
    rego: { enabled: true },
    roc: { enabled: false },
  },
  otherRequirements: '',
}

export type TenderFormRef = {
  resetForm: () => void
}

const TenderForm: React.FC<TenderFormProps> = ({
  onSubmit,
  onCancel,
  isLoading,
  defaultValues,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.tenders.drawerCreateUpdateTender.form',
  })

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    watch,
    register,
  } = useForm<FormValues>({
    defaultValues: formDefaultValues,
  })

  useEffect(() => {
    reset(defaultValues || formDefaultValues)
  }, [defaultValues])

  const { user } = useUser<'GENERATOR'>()

  const minStartDate = useMemo(() => {
    if (user?.adminActing?.userId) {
      return null
    }
    const now = dayjs()
    return now.get('hours') < 10
      ? now.add(1, 'week').format(dateFormats.aws)
      : now.add(8, 'days').format(dateFormats.aws)
  }, [user])

  const minEndDate = useMemo(() => {
    const startDate = watch('startDate')
    if (!startDate) return ''
    return dayjs(startDate)
      .add(1, 'month')
      .endOf('month')
      .format(dateFormats.aws)
  }, [watch('startDate')])

  const labelEndDate = useMemo(() => {
    const undefinedLabel = 'dd/mm/yyyy'

    const startDate = watch('startDate')
    const endDate = watch('endDate')
    const customEndDate = watch('customEndDate')
    if (!startDate) return undefinedLabel

    if (customEndDate) {
      if (!endDate) return undefinedLabel
      return dayjs(endDate).format(dateFormats.user)
    }

    const duration = watch('durationMonths')
    if (!duration?.value) return undefinedLabel
    const durationInt = parseInt(duration.value, 10)

    return dayjs(startDate)
      .add(durationInt, 'months')
      .subtract(1, 'month')
      .endOf('month')
      .format(dateFormats.user)
  }, [
    watch('startDate'),
    watch('endDate'),
    watch('customEndDate'),
    watch('durationMonths'),
  ])

  useEffect(() => {
    const customEndDate = watch('customEndDate')
    if (customEndDate) {
      register('endDate')
    } else {
      register('durationMonths')
    }
  }, [watch('customEndDate')])

  const selectedSites = watch('sites') || []

  const isRocDisabled = useMemo(() => {
    const hasRocBandNull = selectedSites.some(
      (site: any) => site.rocBand === null,
    )

    return hasRocBandNull
  }, [selectedSites])

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col w-full gap-y-5"
    >
      <div className="flex flex-col gap-y-2">
        <div className="flex items-start gap-x-6">
          <div className="flex flex-1">
            <Controller
              name="startDate"
              control={control}
              rules={{
                required: { value: true, message: t('startDate.required') },
              }}
              render={({ field: props }) => (
                <InputGroup
                  label={t('startDate.title')}
                  error={errors.startDate?.message}
                  required
                >
                  <Input
                    type="date"
                    {...props}
                    min={minStartDate}
                    placeholder={t('startDate.placeholder')}
                    error={errors.startDate?.message}
                  />
                </InputGroup>
              )}
            />
          </div>

          <div className="flex flex-1">
            {watch('customEndDate') ? (
              <Controller
                name="endDate"
                control={control}
                rules={{
                  required: { value: true, message: t('endDate.required') },
                  validate: {
                    endOfMonth: (value) => {
                      if (!value) {
                        return t('endDate.required')
                      }

                      const endDateEndOfMonth = dayjs(value)
                        .endOf('month')
                        .format(dateFormats.aws)

                      if (value !== endDateEndOfMonth) {
                        return t('endDate.ruleEndOfMonth')
                      }
                      return undefined
                    },
                  },
                }}
                render={({ field: props }) => (
                  <InputGroup
                    label={t('endDate.title')}
                    error={errors.endDate?.message}
                    required
                  >
                    <Input
                      type="date"
                      {...props}
                      min={minEndDate}
                      placeholder={t('endDate.placeholder')}
                      error={errors.endDate?.message}
                    />
                  </InputGroup>
                )}
              />
            ) : (
              <Controller
                name="durationMonths"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t('durationMonths.required'),
                  },
                }}
                render={({ field: { ref: _, ...props } }) => (
                  <InputGroup
                    label={t('durationMonths.title')}
                    error={errors.durationMonths?.message}
                    required
                  >
                    <Select
                      {...props}
                      placeholder="0"
                      options={optionsDurations}
                      error={errors.durationMonths?.message}
                    />
                  </InputGroup>
                )}
              />
            )}
          </div>
        </div>

        <div className="flex items-end justify-start text-ppa/title">
          <div
            className={classNames(
              'flex items-center justify-start mr-1',
              watch('customEndDate')
                ? 'opacity-0 pointer-events-none select-none absolute -top-full'
                : 'opacity-100',
              transition,
            )}
          >
            <span>{t('endDate.label')}</span>
            &nbsp;
            <strong>{labelEndDate}</strong>
          </div>
          {watch('customEndDate') ? (
            <button
              type="button"
              className={classNames(
                transition,
                'text-ppa/primary underline font-medium text-sm hover:brightness-75 cursor-pointer',
              )}
              onClick={() => {
                setValue('customEndDate', false)
              }}
            >
              {t('endDate.enableDuration')}
            </button>
          ) : (
            <button
              type="button"
              className={classNames(
                transition,
                'text-ppa/primary underline font-medium text-sm hover:brightness-75 cursor-pointer',
              )}
              onClick={() => {
                setValue('customEndDate', true)
                setValue('endDate', '')
              }}
            >
              {t('endDate.enableCustomEndDate')}
            </button>
          )}
        </div>
      </div>

      <Controller
        name="sites"
        control={control}
        rules={{
          required: {
            value: true,
            message: t('sites.required'),
          },
        }}
        render={({ field: { ref: _, ...props } }) => (
          <InputGroup
            label={t('sites.title')}
            error={errors.sites?.message}
            required
          >
            <SelectSites
              {...props}
              placeholder={t('sites.placeholder')}
              error={errors.sites?.message}
            />
          </InputGroup>
        )}
      />

      <InputGroup label={t('subsidies.title')}>
        {isRocDisabled && (
          <span className="my-2">
            <Message variant="info" size="text-xs self-center">
              {t('subsidies.infoMessage')}
            </Message>
          </span>
        )}
        <div className="flex items-center gap-3">
          <Controller
            name="subsidies.rego.enabled"
            control={control}
            render={({ field: props }) => <Checkbox {...props} label="REGO" />}
          />

          <Controller
            name="subsidies.roc.enabled"
            control={control}
            render={({ field: props }) => (
              <Checkbox
                label="ROC"
                {...props}
                disabled={
                  isRocDisabled ||
                  (watch('subsidies.fit.enabled') &&
                    !watch('subsidies.roc.enabled'))
                }
              />
            )}
          />

          <Controller
            name="subsidies.fit.enabled"
            control={control}
            render={({ field: props }) => (
              <Checkbox
                label="FIT"
                {...props}
                disabled={
                  watch('subsidies.roc.enabled') &&
                  !watch('subsidies.fit.enabled')
                }
              />
            )}
          />
        </div>
      </InputGroup>

      <Controller
        name="participatingSuppliers"
        control={control}
        render={({ field: { ref: _, ...props } }) => (
          <InputGroup
            label={t('participatingSuppliers.title')}
            infoLabel={t('participatingSuppliers.infoLabel')}
            error={errors.participatingSuppliers?.message}
          >
            <SelectSuppliers
              {...props}
              placeholder={t('participatingSuppliers.placeholder')}
              error={errors.participatingSuppliers?.message}
            />
          </InputGroup>
        )}
      />

      <Controller
        name="otherRequirements"
        control={control}
        render={({ field: props }) => (
          <InputGroup
            label={t('otherRequirements.title')}
            infoLabel={t('otherRequirements.infoLabel')}
            error={errors.otherRequirements?.message}
          >
            <Textarea
              {...props}
              placeholder={t('otherRequirements.placeholder')}
            />
          </InputGroup>
        )}
      />

      <div>
        <RequiredField />
      </div>

      <div className="flex items-center justify-end w-full gap-x-5">
        {onCancel && (
          <div className="max-w-[100px] w-full">
            <Button
              type="button"
              onClick={onCancel}
              variant="secondary"
              fullWidth
              disabled={isLoading}
            >
              {t('cancel')}
            </Button>
          </div>
        )}

        <div className="max-w-[150px] w-full">
          <Button type="submit" variant="primary" fullWidth loading={isLoading}>
            {t('submit')}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default TenderForm
