import React from 'react'

import classNames from 'classnames'

import { ConsumerTenderDetailsProps } from '../details'

import ConsumerTenderDetails from '../components/details'
import BidDetails from '../components/details-bid'
import SitesTables from '../components/table-sites'

const ConsumerTenderUnderOfferDetails: React.FC<ConsumerTenderDetailsProps> = ({
  tenderInvitation,
  refetch,
}) => {
  return (
    <>
      <div className="flex flex-col 2xl:flex-row">
        <div className="flex-1">
          <ConsumerTenderDetails tender={tenderInvitation} />
        </div>
        <div
          className={classNames(
            'border-ppa/grayBorder',
            'border-t mt-10 pt-10',
            '2xl:border-t-0 2xl:border-l 2xl:mt-10 2xl:pt-0 2xl:ml-10 2xl:pl-10',
            'flex-1',
          )}
        >
          <BidDetails
            consumerTenderInvitation={tenderInvitation}
            refetchBidList={refetch}
          />
        </div>
      </div>
      <SitesTables tenderData={tenderInvitation.consumerTender} />
    </>
  )
}

export default ConsumerTenderUnderOfferDetails
