import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'
import { UploadIcon, XIcon } from '@heroicons/react/outline'
import classNames from 'classnames'

const acceptTypes = {
  image: {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
  },
  dataText: {
    'text/plain': ['.xlsx', '.xls', '.csv', '.txt'],
  },
  pdf: {
    'application/pdf': ['.pdf'],
  },
}

export interface InputFileProps {
  name: string
  multiple?: boolean
  acceptFiles?: keyof typeof acceptTypes
  [key: string]: any
}

const textClasses = 'text-ppa/title font-medium text-sm'
const InputFile: React.FC<InputFileProps> = ({
  multiple = false,
  setValue,
  name,
  clickToRemove,
  acceptFiles,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'utils.inputFile',
  })

  const [currentFiles, setCurrentFiles] = useState<File[]>([])

  const handleDelete = useCallback(
    (fileToDelete: File) => {
      if (!multiple) {
        setValue(name, undefined)
        setCurrentFiles([])
        return
      }
      const newArrayFiles = currentFiles.filter(
        (item) => item.name !== fileToDelete.name,
      )

      if (newArrayFiles.length === 0) {
        setValue(name, undefined)
        setCurrentFiles([])
        return
      }

      setValue(name, newArrayFiles)
      setCurrentFiles(newArrayFiles)
    },
    [currentFiles, setValue, multiple],
  )

  const onDrop = useCallback(
    (uploadedFiles: any) => {
      if (!multiple) {
        if (!uploadedFiles || !uploadedFiles[0]) return
        setValue(name, uploadedFiles[0])
        setCurrentFiles([uploadedFiles[0]])
        return
      }

      setValue(name, uploadedFiles)
      setCurrentFiles(uploadedFiles)
    },
    [setValue, multiple],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    accept: acceptFiles ? { ...acceptTypes[acceptFiles] } : undefined,
  })

  return (
    <div>
      <div className="flex">
        <div
          {...getRootProps()}
          className="flex items-center justify-center py-3 px-4 border-dashed border border-ppa/title max-w-[200px] cursor-pointer"
        >
          <input {...getInputProps()} data-testid="file-input" />
          <UploadIcon
            className={classNames(
              'w-full h-full max-w-[28px] max-h-[28px] stroke-ppa/primary transition-colors delay-200 mr-4',
              isDragActive && 'stroke-ppa/green-600',
            )}
          />
          <strong
            className={classNames(
              textClasses,
              isDragActive ? 'fade-in' : 'hidden',
            )}
          >
            {t('dropFilesHere')}
          </strong>
          <strong
            className={classNames(
              textClasses,
              isDragActive ? 'hidden' : 'fade-in',
            )}
          >
            {t('clickOrDropToUpload')}
          </strong>
        </div>
        <div className="flex flex-col items-start justify-start ml-2">
          {currentFiles &&
            currentFiles.map((item) => (
              <span
                key={item.name}
                className="text-ppa/title text-sm flex items-center justify-start"
              >
                <XIcon
                  className="h-4 w-4 stroke-3 stroke-ppa/primary cursor-pointer hover:brightness-75 mr-2 transition delay-200"
                  onClick={() => handleDelete(item)}
                />
                {item.name}
              </span>
            ))}
        </div>
      </div>
      <div className="flex-col">
        {currentFiles &&
          clickToRemove &&
          currentFiles.map((item) => (
            <button
              type="button"
              key={item.name}
              className="text-xs underline underline-offset-1 mt-2"
              onClick={() => handleDelete(item)}
            >
              {t('clickToRemove')}
            </button>
          ))}
      </div>
    </div>
  )
}

export default InputFile
