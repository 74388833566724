import ConsumerTendersActive from './active'
import ConsumerTendersActiveDetails from './active/details'

import ConsumerTendersDraft from './draft'
import ConsumerDraftTenderDetails from './draft/details'

import ConsumerTendersPending from './pending'
import ConsumerPendingTenderDetails from './pending/details'

import ConsumerTendersWithdrawn from './withdrawn'
import ConsumerWithdrawnTenderDetails from './withdrawn/details'

import ConsumerTendersUnderOffer from './under-offer'
import ConsumerTenderUnderOfferDetails from './under-offer/details'

import ConsumerTendersExecuted from './executed'
import ConsumerTendersExecutedDetails from './executed/details'

export const consumerTendersLinks = [
  { to: '/status/active', label: 'tenderStatus.active' },
  { to: '/status/draft', label: 'tenderStatus.draft' },
  { to: '/status/pending', label: 'tenderStatus.pending' },
  { to: '/status/withdrawn', label: 'tenderStatus.withdrawn' },
  { to: '/status/underOffer', label: 'tenderStatus.underOffer' },
  { to: '/status/executed', label: 'tenderStatus.executed' },
]

export const consumerTendersSubRoutes = {
  active: {
    index: <ConsumerTendersActive />,
    details: ConsumerTendersActiveDetails,
  },
  draft: {
    index: <ConsumerTendersDraft />,
    details: ConsumerDraftTenderDetails,
  },
  pending: {
    index: <ConsumerTendersPending />,
    details: ConsumerPendingTenderDetails,
  },
  withdrawn: {
    index: <ConsumerTendersWithdrawn />,
    details: ConsumerWithdrawnTenderDetails,
  },
  underOffer: {
    index: <ConsumerTendersUnderOffer />,
    details: ConsumerTenderUnderOfferDetails,
  },
  executed: {
    index: <ConsumerTendersExecuted />,
    details: ConsumerTendersExecutedDetails,
  },
}
