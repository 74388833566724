export const defaultFeatures = {
  staffAdminAddGenerator: false,
  staffAdminTenderDetailsButtons: false,
  documentsPage: false,
  supplierSeeBidButton: false,
  restOfAdvertisements: false,
  bidTableTotalRow: false,
  supplierLogoFile: false,
  memberStatusAdmin: false,
  showNotificationAction: false,
  passwordPage: false,
  notificationsPage: true,
  companiesDetailsPage: true,
  searchFilterMeter: false,
}

function getFeatures() {
  const storedFeatures = localStorage.getItem('@PPAYA:features')
  return storedFeatures ? JSON.parse(storedFeatures) : defaultFeatures
}

function setFeatures(features: Record<string, boolean>) {
  return localStorage.setItem('@PPAYA:features', JSON.stringify(features))
}

export function isFeatureEnabled(feature: string) {
  return getFeatures()[feature] || false
}

export function toggleFeature(feature: string, enable = true) {
  setFeatures({ ...getFeatures(), [feature]: enable })
}

function toggleAllFeatures(enable: boolean) {
  const newFeatures: Record<string, boolean> = {}
  Object.entries({ ...getFeatures(), ...defaultFeatures }).forEach(
    ([feature]) => {
      newFeatures[feature] = enable
    },
  )
  setFeatures(newFeatures)
}
export function enableAllFeatures() {
  toggleAllFeatures(true)
}

export function resetAllFeatures() {
  toggleAllFeatures(false)
}

/* eslint-disable */
;(window as any).togglePpayaFeature = toggleFeature
;(window as any).enableAllPpayaFeatures = enableAllFeatures
;(window as any).resetAllPpayaFeatures = resetAllFeatures
/* eslint-enable */
