import React from 'react'

import { TenderDetailsSupplierProps } from '../details'

import TenderDetails from '../components/details'

const TendersPendingDetails: React.FC<TenderDetailsSupplierProps> = ({
  tenderInvitation,
}) => {
  return (
    <div className="flex flex-col 2xl:flex-row">
      <div className="flex-1">
        <TenderDetails tenderInvitation={tenderInvitation} />
      </div>
    </div>
  )
}

export default TendersPendingDetails
