import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'

import usePPA from '../../../../../hooks/use-ppa'

import { UserTypes } from '../../../../../types/user'

import SlidingDrawer from '../../../../../components/molecules/sliding-drawer'

import { feedbackMessage } from '../../../../../components/atoms/feedback'

import Select, { SelectOptions } from '../../../../../components/atoms/select'
import Button from '../../../../../components/atoms/button'

type FormValues = {
  memberType?: SelectOptions
}

export interface ChangeMemberTypeProps {
  userType: UserTypes
  userId: string | null
  isOpen?: boolean
  handleClose: () => void
  onSuccess: () => void
}

const ChangeMemberTypeDrawer: React.FC<ChangeMemberTypeProps> = ({
  handleClose,
  onSuccess,
  userType,
  userId,
  isOpen = false,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.generators.details.changeMemberTypeDrawer',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      memberType: undefined,
    },
  })

  const handleOnSubmit = async (formData: FormValues) => {
    if (isLoading) return
    setIsLoading(true)

    try {
      const url =
        userType === 'CONSUMER'
          ? `/core/private/${userType.toLocaleLowerCase()}/admin/member-type/${userId}`
          : `/core/private/${userType.toLocaleLowerCase()}/member-type/${userId}`

      const { error, response } = await fetchData({
        method: 'PATCH',
        url,
        body: {
          memberType: formData?.memberType?.value,
        },
      })

      if (error || !response) {
        throw error
      }

      feedbackMessage(
        { title: tUtils('feedbackMessage.success.title') },
        'success',
      )

      onSuccess()
    } catch (err) {
      throwFeedbackError({
        err,
      })
    }
    reset()
    setIsLoading(false)
    handleClose()
  }

  return (
    <SlidingDrawer isOpen={isOpen} handleClose={handleClose}>
      <div className="flex flex-col gap-y-5 sm:w-[495px]">
        {isOpen && (
          <form
            onSubmit={handleSubmit(handleOnSubmit)}
            className="flex flex-col gap-y-5 mx-1"
          >
            <h1 className="text-xl font-semibold text-ppa/title">
              {t('title')}
            </h1>
            <Controller
              name="memberType"
              control={control}
              render={({ field: props }) => (
                <Select
                  {...props}
                  type="single"
                  options={[
                    {
                      value: 'OWNER',
                      label: t('form.memberType.OWNER'),
                    },
                    {
                      value: 'MANAGER',
                      label: t('form.memberType.MANAGER'),
                    },
                    {
                      value: 'REGULAR',
                      label: t('form.memberType.REGULAR'),
                    },
                  ]}
                  error={errors.memberType?.message}
                />
              )}
            />
            <div className="flex items-center justify-end gap-x-5">
              <div className="max-w-[90px] w-full">
                <Button
                  type="submit"
                  variant="quaternary"
                  fullWidth
                  loading={isLoading}
                  onClick={handleClose}
                >
                  {t('form.button.cancel')}
                </Button>
              </div>
              <div className="max-w-[150px] w-full">
                <Button
                  type="submit"
                  variant="primary"
                  fullWidth
                  loading={isLoading}
                >
                  {t('form.button.submit')}
                </Button>
              </div>
            </div>
          </form>
        )}
      </div>
    </SlidingDrawer>
  )
}

export default ChangeMemberTypeDrawer
